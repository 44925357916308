import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  professor: {
    userId: "",
    info: {
      profilePhoto: '',
      name: {
        first: '',
        middle: '',
        last: ''
      },
      desc: '',
      email: '',
      linkedin: '',
      mobile: ['',],
      address: '',
      landmark: '',
      pincode: '',
      city: '',
      district: '',
      state: '',
      languages: []
    },
    education: [{
      name: '',
      degree: '',
      field: '',
      gradYear: '',
      profileDesc: ''
    }],
    experience: [{
      designation: '',
      org: '',
      startDate: '',
      endDate: '',
      desc: ''
    }],
    specializations: {
      intro: '',
      specialization: [{
        name: '',
        desc: ''
      }]
    }
  }
};

const professorSlice = createSlice({
  name: "professor",
  initialState,
  reducers: {
    updateProfessorPersonalInfo: (state, action) => {
      state.professor.info = { ...state.professor.info, ...action.payload };
    },
    updateProfessorEducationBackground: (state, action) => {
      state.professor.education = action.payload;
    },
    addProfessorEducationBackground: (state) => {
      state.professor.education.push({ name: '', degree: '', field: '', gradYear: '', profileDesc: '' });
    },
    updateProfessorExperience: (state, action) => {
      state.professor.experience = action.payload;
    },
    addProfessorExperience: (state) => {
      state.professor.experience.push({ designation: '', org: '', startDate: '', endDate: '', desc: '' });
    },
    updateProfessorSpecializationIntroText: (state, action) => {
      state.professor.specializations.intro = action.payload;
    },
    updateProfessorSpecialization: (state, action) => {
      const { index, key, value } = action.payload;
      state.professor.specializations.specialization[index][key] = value;
    },
    addProfessorSpecialization: (state) => {
      state.professor.specializations.specialization.push({ name: '', desc: '' });
    },
    updateProfessorSpecializationData: (state, action) => {
      state.professor.specializations = action.payload;
    },
    updateProfUserId: (state, action) => {
      state.professor.userId = action.payload;
    },
    resetProfessor: () => initialState,
    updateProfessorData: (state, action) => {
      state.professor = action.payload;
    },
  },
});

export const {
  updateProfessorPersonalInfo,
  updateProfessorEducationBackground,
  addProfessorEducationBackground,
  updateProfessorExperience,
  addProfessorExperience,
  updateProfessorSpecializationIntroText,
  updateProfessorSpecialization,
  addProfessorSpecialization,
  updateProfessorSpecializationData,
  resetProfessor,
  updateProfessorData,
  updateProfUserId
} = professorSlice.actions;

export const selectProfessor = (state) => state.professor.professor;

export default professorSlice.reducer;
