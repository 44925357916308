import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAsync, selectEmail, selectOtp } from '../../redux/authSlice';
import { toast } from 'react-toastify';

const ConfirmPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const email = useSelector(selectEmail) || '';
    const otp = useSelector(selectOtp) || '';
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [localError, setLocalError] = useState('');
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { password, confirmPassword } = formData;

        // Basic validation
        if (password.length < 8 || confirmPassword.length < 8) {
            setLocalError('Password must be at least 8 characters long');
            return;
        }
        if (password !== confirmPassword) {
            setShowError(true);
            return;
        }

        setShowError(false);
        setIsLoading(true);

        try {
            // Log the form data
            // console.log("All Data: ", email, otp, password);

            await dispatch(resetPasswordAsync({ email, otp, newPassword: password })).unwrap();
            // On success, redirect to login
            toast.success('Password Changed Successfully :)');
            navigate("/");
        } catch (error) {
            console.error("Failed to reset password: ", error);
            setLocalError('Failed to reset password');
            // On failure, redirect to forgot password
            toast.error(`Error : ${error.message}\n Please try again :)`);
            navigate("/forgot-password");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="absolute top-4 right-4">
                <span className='text-black'>No account? </span>
                <Link to="/signup" className="text-blue-600 hover:underline">Sign up</Link>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-4 text-center">Hi, Welcome Back!</h2>
                <p className="text-gray-600 mb-6 text-center">Connect with your friend today!</p>
                <form onSubmit={onSubmit} className="space-y-8">
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="text"
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                        {showError && formData.password !== formData.confirmPassword && (
                            <span className='text-[#FF0808] flex justify-end pt-3'>Must match New Password</span>
                        )}
                    </div>
                    <div className="flex flex-col gap-4 pt-8 sm:pt-10">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        >
                            {isLoading ? (
                                <>
                                    <span className="loader animate-spin"></span> &nbsp;
                                    Loading...
                                </>
                            ) : "Proceed"}
                        </button>

                        {localError && <p className="mt-2 text-center text-red-600 text-sm">{localError}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ConfirmPassword;
