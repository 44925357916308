import API from './config';

export const createPdf = async(pdf) => {
  try {
    const res = await API.post('api/pdfs', pdf, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updatePdf = async(id, pdf) => {
  try {
    const res = await API.put(`api/pdfs/${id}`, pdf, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export const getPdfById = async(id) => {
  try {
    const res = await API.get(`api/pdfs/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

// dashboard

export const getAllPdfs = async() => {
  try {
    const res = await API.get(`api/pdfs`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const togglePdfActive = async(id) => {
  try {
    const res = await API.put(`api/pdfs/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const deletePdf = async(id) => {
  try {
    const res = await API.delete(`api/pdfs/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}