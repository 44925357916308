import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { selectLoggedInUser, setUserFromLocalStorage } from '../../redux/authSlice';

function Protected({ children }) {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectLoggedInUser);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            dispatch(setUserFromLocalStorage());
        }
        setLoading(false);
    }, [dispatch]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!loggedInUser) {
        return <Navigate to='/' replace={true} />;
    }

    return children;
}

export default Protected;
