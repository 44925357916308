import React, {  useEffect, useState } from "react";

import SideBar from "../Parts/SideBar2";
import searchIcon from "../../assets/search-lg.svg";
import FieldCol1 from "../Parts/FieldCol1";
import cross from "../../assets/cross.svg";
import cloud from "../../assets/upload-cloud-02.svg";
import SubTopicResource from "../Parts/SubTopicResource";
import Imagekit from "../Parts/Imagekit";
import Popup from "./Popup";
import RefDropDown from "../Parts/RefDropDown";
import { getProfessors } from "../../api/professorApi";
import { addCourse, editCourse, getCourseById } from "../../api/courseApi";
import { useParams } from "react-router-dom";
import { getUniversities } from "../../api/universityApi";
import { getTopics } from "../../api/topicApi";
import { toast } from 'react-toastify';

const AddCourse = () => {
  let { id } = useParams();
  const [userData, setUserData] = useState({
    thumbnailImage: "",
    courseName: '',
    numberOfTopics: '',
    numberOfArticles: '',
    numberOfPDFs: '',
    professorId: '',
    lengthOfVideoInMinutes: '',
    credits: '',
    streams: [],
    universityIds: [],
    universityName: "",
    requirements: "",
    description: '',
    topics: [],
  });
  const [selectedId, setSelectedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterTerm, setFilterTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isVisible, setIsVisible] = useState(false)

  const [professors, setProfessors] = useState([]);

  const [universities, setUniversities] = useState([]);
  const [selectedUnis, setSelectedUnis] = useState([]);

  const [newStream, setNewStream] = useState("");
  const [courseId, setCourseId] = useState("");

  const [selectedTopicData, setSelectedTopicData] = useState([]);

  useEffect(() => {
    if (selectedTopicData.length > 0) {
      const totalTopics = selectedTopicData.length;
      const totalArticles = selectedTopicData.reduce((acc, topic) => acc + topic.numberOfArticles, 0);
      const totalPDFs = selectedTopicData.reduce((acc, topic) => acc + topic.numberOfPDFs, 0);
      const totalLengthOfVideos = selectedTopicData.reduce((acc, topic) => acc + topic.lengthOfVideosInMinutes, 0);
      const totalCredits = selectedTopicData.reduce((acc, topic) => acc + topic.credits, 0);

      const allProfessorIds = selectedTopicData.map(topic => topic.professorId);
      const uniqueProfessorIds = [...new Set(allProfessorIds)];
      const professorId = uniqueProfessorIds.length === 1 ? uniqueProfessorIds[0] : '';

      setUserData(prev => ({
        ...prev,
        numberOfTopics: totalTopics,
        numberOfArticles: totalArticles,
        numberOfPDFs: totalPDFs,
        lengthOfVideoInMinutes: totalLengthOfVideos,
        credits: totalCredits,
        professorId: {
          _id: professorId
        },
      }));
    }
  }, [selectedTopicData]);

  useEffect(() => {
    // get universities and professors for drop down
    const fetchUniversities = async () => {
      try {
        const res = await getUniversities();
        const data = res.map(u => u = { _id: u._id, universityName: u.overview.name })
        setUniversities(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProfessors = async () => {
      try {
        const res = await getProfessors();
        const data = res.map(p => p = { _id: p._id, professorId: `${p.info.name.first} ${p.info.name.last}` });
        setProfessors(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUniversities();
    fetchProfessors();
  }, [])

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const res = await getCourseById(id);
        const fetched = res.data;

        setUserData(fetched);
        // get university names from ids in course
        const names = fetched.universityIds.map(uniId => {
          const uni = universities.find(u => u._id === uniId);
          return uni ? uni.universityName : "";
        });
        setSelectedUnis(names);
      } catch (error) {
        console.error(error);
      }
    };

    if (id) fetchCourseData();
  }, [id, universities])

  useEffect(() => {
    // whenever userData.topics change, filter the data here
    const fetchTopics = async () => {
      try {
        // get remaining topics and set availability to true (visible) initially.
        const res = await getTopics();

        setFilteredData(res
          .filter(topic => !userData.topics.some(t => t._id === topic._id))
          .map(topic => ({ _id: topic._id, topicName: topic.topicName, available: true })
          ));

        setSelectedTopicData(res
          .filter(topic => userData.topics.some(t => t._id === topic._id))
          .map(topic => ({ ...topic })
          ))
      } catch (error) {
        console.error(error);
      }
    }

    fetchTopics();
  }, [userData.topics]);

  useEffect(() => {
    let filtered = filteredData;
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.topicName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (filterTerm) {
      setFilterTerm(filtered.filter(item =>
        item.category.toLowerCase().includes(filterTerm.toLowerCase())
      ));
    }
    setFilteredData(filtered.slice(0, 10));
  }, [searchTerm, filterTerm]);

  const handleData = (e) => {
    const { name, value } = e.target;

    if (name === "universityName") {
      const selection = universities.find(u => (u._id === value));
      if (selection) {
        // add new element in set (keep all unique)
        setUserData(prev => ({
          ...prev,
          universityIds: [...new Set([...prev.universityIds, selection._id])],
          universityName: ""
        }));

        // add in selectedUnis set
        setSelectedUnis(prev => {
          return [...new Set([...prev, selection.universityName])];
        })
      }
    }
    else setUserData(prev => ({ ...userData, [name]: value }));
    // console.log(userData);
  };

  const deleteUni = u => {
    const chosen = universities.find(uni => uni.universityName === u);

    setSelectedUnis(selectedUnis.filter(t => t != u))
    setUserData(prev => ({
      ...prev,
      universityIds: prev.universityIds.filter(t => t !== chosen._id)
    }));
  };

  const addStream = e => {
    if (e.key === "Enter" && newStream.trim()) {
      e.preventDefault();
      setUserData(prev => ({
        ...prev, streams: [...prev.streams, newStream.trim()]
      }));
      setNewStream("");
    }
  }

  const deleteStream = tag => {
    setUserData(prev => ({
      ...prev, streams: [prev.streams.filter(t => t !== tag)]
    }));
  }

  const handleListSubmit = () => {
    // add in userData and remove from filteredData if false
    setUserData(prev => {
      const newTopics = filteredData.filter(t => !t.available);
      return { ...prev, topics: [...prev.topics, ...newTopics] };
    });
    // only keep true (or available) elements
    setFilteredData(prev => prev.filter(t => t.available));
  }

  const handleCourseSubmit = async () => {
    if (!userData.courseName) {
      toast.error("Please fill the required field: Course Name.");
      return;
    }

    if (!userData.numberOfTopics) {
      toast.error("Please fill the required field: Number of Topics.");
      return;
    }

    if (!userData.numberOfArticles) {
      toast.error("Please fill the required field: Number of Articles.");
      return;
    }

    if (!userData.numberOfPDFs) {
      toast.error("Please fill the required field: Number of PDFs.");
      return;
    }

    if (!userData.lengthOfVideoInMinutes) {
      toast.error("Please fill the required field: Length of Video in Minutes.");
      return;
    }

    if (!userData.credits) {
      toast.error("Please fill the required field: Credits.");
      return;
    }

    if (!userData.description) {
      toast.error("Please fill the required field: Description.");
      return;
    }

    // Professor ID validation
    if (!userData.professorId) {
      toast.error("Please select a Professor.");
      return;
    }

    if (!userData.topics || userData.topics.length === 0) {
      toast.error("Please add at least one Topic.");
      return;
    }

    // for (let i = 0; i < topicData.resources.length; i++) {
    //   if (!topicData.resources[i].ranking || topicData.resources[i].ranking === "0") {
    //     alert("Please ensure that each resource has a valid ranking.");
    //     return;
    //   }
    // }

    try {
      // post data
      const { universityName, ...courseData } = userData;
      const res = !id
        ? await addCourse(courseData)
        : await editCourse(id, courseData);

      // get _id. pass to price. pass its id to course by getting editing course by id
      if (res?._id) {
        id = res._id;
        setCourseId(id);
        setIsVisible(true);
        toast.success(id ? "Course updated successfully." : "Course added successfully.");
      } else {
        toast.error("Error, please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the data.");
    }
  }
  // console.log(userData);

  return (
    <div className="bg-purple-50">
      <div className=" justify-start items-start inline-flex">
        <SideBar />

        <div className="grow ml-[15.5vw] shrink basis-0 self-stretch justify-start items-start inline-flex">
          <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
            <div className="grow shrink basis-0 px-8 justify-center items-start flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch justify-start items-start gap-8 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                          <p className="text-zinc-700 text-4xl font-bold   leading-tight">
                            Add Course
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-start gap-6 inline-flex">
                      <label htmlFor="thumbnail" className="w-[512px] cursor-pointer flex-col justify-start items-start gap-4 inline-flex">

                        <div className="self-stretch h-[126px] bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                          <div className="self-stretch h-full justify-center items-center gap-10 flex">
                            <Imagekit
                              id="thumbnail"
                              onSuccess={(res) => {
                                const updated = {
                                  ...userData,
                                  thumbnailImage: res.url,
                                };
                                setUserData(updated);
                              }}
                            />
                            <div className=" flex-col justify-start items-start gap-1 flex">
                              <div className="self-stretch justify-center items-start gap-1 inline-flex">
                                <div className="justify-center items-center gap-2 flex">
                                  <p className="text-gray-900 text-sm font-semibold leading-tight">
                                    Click to upload Thumbnail image
                                  </p>
                                </div>
                              </div>
                              <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                                SVG, PNG or JPG(max. 800x400px)
                              </p>
                            </div>
                            {userData.thumbnailImage ? (
                              <img
                                src={userData.thumbnailImage}
                                alt="profile photo"
                                className="w-[100px] h-[100px]"
                              />
                            ) : (
                              <div className="w-15 h-15 p-2.5 bg-gray-200 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                                <img
                                  src={cloud}
                                  alt="cloud"
                                  className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[600px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="courseName"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Course Name<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="courseName"
                                  type="text"
                                  placeholder="Prabandhnagar"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={userData.courseName}
                                  onChange={e => handleData(e)}
                                  name="courseName"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[153px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="numberOfTopics"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Number of topics<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="numberOfTopics"
                                  type="number"
                                  placeholder=""
                                  min={1}
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal w-[130px]  leading-normal focus:outline-none"
                                  value={userData.numberOfTopics}
                                  onChange={handleData}
                                  name="numberOfTopics"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[153px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="numberOfArticles"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Number of articles<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="numberOfArticles"
                                  type="number"
                                  min={1}
                                  placeholder=""
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal  w-[130px] leading-normal focus:outline-none"
                                  value={userData.numberOfArticles}
                                  onChange={handleData}
                                  name="numberOfArticles"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[153px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="numberOfPDFs"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Number of PDFs<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 w-[153px] bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="numberOfPDFs"
                                  type="text"
                                  placeholder=""
                                  className="grow shrink basis-0 text-zinc-700 text-base w-[130px] font-normal   leading-normal focus:outline-none"
                                  value={userData.numberOfPDFs}
                                  onChange={handleData}
                                  name="numberOfPDFs"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">

                            <RefDropDown fieldName={"Professor Name"} array={professors} currentValue={userData.professorId._id} required display={"professorId"} theValue={"_id"} handleInput={handleData} />

                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[236px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="lengthOfVideoInMinutes"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Length of Video in minutes<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="lengthOfVideoInMinutes"
                                  type="number"
                                  placeholder=""
                                  min={1}
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal w-[130px]  leading-normal focus:outline-none"
                                  value={userData.lengthOfVideoInMinutes}
                                  onChange={handleData}
                                  name="lengthOfVideoInMinutes"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] w-[153px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="credits"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Credits<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 w-[153px] bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="credits"
                                  type="number"
                                  min={1}
                                  placeholder=""
                                  className="grow shrink basis-0 text-zinc-700 text-base w-[130px] font-normal   leading-normal focus:outline-none"
                                  value={userData.credits}
                                  onChange={handleData}
                                  name="credits"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* streams */}
                    <div className=" self-stretch w-full">
                      <label
                        htmlFor="tags"
                        className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                      >
                        Streams
                      </label>

                      <div className="flex-grow px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 gap-2 flex">
                        {userData.streams?.map((tag, i) => (
                          <div key={i} className=" flex items-center ">
                            <div className="px-3 bg-purple-100 rounded-[30px] justify-center items-center gap-1 flex">
                              <div className="text-zinc-700 text-base font-normal font-['Inter'] leading-normal flex-nowrap max-w-[120px] min-w-[20px] max-h-6 overflow-hidden">
                                {tag}
                              </div>
                              <img src={cross} alt="Remove stream" onClick={() => deleteStream(tag)} className="cursor-pointer" />
                            </div>
                          </div>
                        ))}
                        <input
                          type="text"
                          value={newStream}
                          onChange={e => setNewStream(e.target.value)}
                          onKeyDown={addStream}
                          placeholder="+ Add Stream"
                          className="flex-grow focus:outline-none"
                        />
                      </div>
                    </div>

                    {/* universities */}
                    <div className="self-stretch w-full gap-6 flex">
                      <div className="self-stretch  w-[310px] h-[70px] flex-col justify-start items-start gap-1.5 flex">

                        <RefDropDown fieldName={"Universities"} array={universities} currentValue={userData.universityIds[userData.universityIds.length - 1]} display={"universityName"} theValue={"_id"} handleInput={handleData} />

                      </div>

                      <div className="flex-grow ">
                        <div className=" h-[70px] flex-col gap-1.5 flex">
                          <label
                            htmlFor="tags"
                            className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                          >
                            Selected Universities
                          </label>
                          <div className="flex-grow px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 gap-2 flex">
                            {selectedUnis?.map((tag, i) => (
                              <div key={i} className=" flex items-center ">
                                <div className="px-3 bg-purple-100 rounded-[30px] justify-center items-center gap-1 flex">
                                  <div className="text-zinc-700 text-base font-normal font-['Inter'] leading-normal flex-nowrap max-w-[120px] min-w-[20px] max-h-6 overflow-hidden">
                                    {tag}
                                  </div>
                                  <img src={cross} alt="Remove university" onClick={() => deleteUni(tag)} className="cursor-pointer" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <FieldCol1
                      fieldName={"Requirements"}
                      fieldPlace={"This contains XXX …."}
                      name={"requirements"}
                      value={userData.requirements}
                      handleInput={handleData}
                    />

                    <FieldCol1
                      fieldName={"Description"}
                      fieldPlace={"This contains XXX …."}
                      name={"description"}
                      required
                      value={userData.description}
                      handleInput={handleData}
                    />

                    <div className=" self-stretch flex-col justify-start items-start gap-1 flex">
                      <h2 className="text-xl font-bold mb-4 ">List of Topics<span className="text-red-500">*</span></h2>
                      <div className=" w-full h-[216px] p-6 bg-purple-100 rounded-lg justify-start items-start gap-4 flex flex-row overflow-x-auto overflow-y-hidden">
                        {userData.topics?.map((topic, i) => (
                          <div key={i}>
                            <SubTopicResource
                              headingName={topic.topicName}
                              handleRanking={e => console.log(e)}
                              handleRemove={() => {
                                setUserData(prev => ({
                                  ...prev,
                                  topics: prev.topics.filter(t => t._id !== topic._id)
                                }))
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="w-[926px] h-[486px] relative bg-white">
                      <div>
                        <div className="left-[199px] top-[24px] absolute justify-start items-center gap-3 inline-flex">
                          <div className="w-[400px] flex-col justify-start items-start gap-2 inline-flex">
                            <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                              <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                  <img src={searchIcon} alt="search" className="w-5 h-5 relative" />
                                  <input
                                    type="text"
                                    className="w-full h-full text-gray-500 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                    placeholder="Search"
                                    value={searchTerm || ''}
                                    onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="w-[730px] left-[137px] top-[90px] absolute justify-start items-start gap-4 grid grid-cols-7">
                          {filteredData?.map((item, index) => (
                            <div
                              key={index}
                              className={`flex-col justify-start items-center gap-2.5 inline-flex cursor-pointer`}
                              onClick={() => {
                                setSelectedId(item._id)
                                // change availability if file is selected.
                                const updated = filteredData.map(file =>
                                  file._id === item._id ? { ...file, available: !file.available } : file
                                );
                                setFilteredData(updated);
                              }}
                              role="button"
                              aria-pressed={selectedId === item._id}
                              tabIndex={0}
                            >
                              <div className={`w-24 h-[72px] bg-zinc-300 rounded-lg ${!item.available ? 'border-2 border-blue-500' : 'border-2 border-transparent'}`}></div>
                              <p className="text-black text-sm font-semibold font-['Source Sans Pro'] truncate w-24" title={item.topicName}>
                                {item.topicName}
                              </p>
                            </div>
                          ))}
                        </div>
                        <button
                          className="w-[730px] px-4 py-2.5 left-[123px] bottom-16 absolute bg-amber-500 rounded-lg shadow justify-center items-center gap-2 inline-flex"
                          onClick={handleListSubmit}
                        >
                          <p className="text-white text-sm font-semibold font-['Inter'] leading-tight">
                            Add to List
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <Popup isVisible={isVisible} onClose={() => setIsVisible(!isVisible)} courseId={courseId} />
                  </div>

                  <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex pt-10">
                    <button
                      onClick={() => {
                        handleCourseSubmit();
                      }}
                      type="submit"
                      className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
                    >
                      <p className="text-white text-base font-semibold   leading-normal">
                        Submit
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;