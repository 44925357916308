import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    overview: {
      name: '',
      yearFounded: '',
      overview: '',
      address: '',
      landmark: '',
      pincode: '',
      city: '',
      district: '',
      state: '',
      universityType: '',
      accreditation: '',
      affiliatedColleges: [],
      ranking: [{
        name: '',
        value: '',
      }]
    },
    campus: {
      about: '',
      size: '',
      facilities: [],
      hostelLife: '',
    },  
    course: {
      offered: '',
      about: [{
        name: '',
        duration: '',
        specialization: [],
        courseFee: '',
        admissionCriteria: '',
        acceptedExams: [],
        hostelFee: '',
        detail: '',
      }]
    },
    placement: {
      intro: '',
      highestLastYear: 0,
      avgLastYear: 0,
      topRecruiters: [],
    },
    gallery: {
      profilePhoto: '',
      logo: '',
      photos: [''],
      videoLink: ['']
    },
    faq: [{
      question: '',
      answer: '',
    }]
};

const universitySlice = createSlice({
  name: "university",
  initialState,
  reducers: {
    updateSection: (state, action) => {
      const { section, data } = action.payload;
      if (section === "faq" || section === "_id" || section === "createdAt" || section === "updatedAt" || section === "__v") state[section] = data;
      else state[section] = { ...state[section], ...data };
    },
    resetUniversity: () => initialState,
  },
});

export const { updateSection, resetUniversity } = universitySlice.actions;
export default universitySlice.reducer;
