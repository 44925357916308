import React from "react";

const ProgressSteps4 = ({ page }) => {
  return (
    <div className="px-12 flex-col justify-start items-center inline-flex" style={{ paddingLeft: "184px" }}>
      <div className="w-[576px] h-[114px] relative">
        <div className="w-[580px] h-0.5 left-[40px] top-[19px] absolute">
          {page === "1" ? (
            <>
              <div className="w-[588px] h-0.5 left-[150px] top-0 absolute bg-gray-200" />
              <div className="w-[240px] h-0.5 left-[20px] top-0 absolute bg-violet-500" />
            </>
          ) : page === "2" ? (
            <>
              <div className="w-[440px] h-0.5 left-[300px] top-0 absolute bg-gray-200" />
              <div className="w-[480px] h-0.5 left-[40px] top-0 absolute bg-violet-500" />
            </>
          ) : page === "3" ? (
            <>
              <div className="w-[680px] h-0.5 left-[60px] top-0 absolute bg-violet-500" />
            </>
          ) : page === "4" && (
            <>
              <div className="w-[720px] h-0.5 left-[20px] top-0 absolute bg-violet-500" />
            </>
          )}
        </div>
        <div className="left-[-40px] top-0 absolute justify-start items-start inline-flex">
          <div className="w-60 flex-col justify-start items-center gap-4 inline-flex">
            <div className="w-10 h-10 bg-purple-50 rounded-[20px] justify-center items-center inline-flex border-2 border-purple-50">
              <div className="grow shrink basis-0 self-stretch py-2 rounded-[20px] border-2 border-violet-500 justify-center items-center inline-flex">
                <div className="text-center text-violet-700 text-base font-semibold leading-normal">
                  1
                </div>
              </div>
            </div>
            <div className="self-stretch h-8 pt-2 flex-col justify-start items-center gap-0.5 flex">
              <div className="self-stretch text-center text-violet-700 text-base font-semibold leading-normal">
                Basic Info
              </div>
            </div>
          </div>
          <div className="w-60 flex-col justify-start items-center gap-4 inline-flex">
            <div className="w-10 h-10 bg-purple-50 rounded-[20px] shadow justify-center items-center inline-flex border-2 border-purple-50">
              <div className={`grow shrink basis-0 self-stretch py-2 bg-purple-50 rounded-[20px] border-2 ${page > 1 ? "border-violet-500" : "border-slate-700"} justify-center items-center inline-flex`}>
                <div className={`text-center ${page > 1 ? "text-violet-700" : "text-slate-700"} text-base font-semibold leading-normal`}>
                  2
                </div>
              </div>
            </div>
            <div className="self-stretch h-8 pt-2 flex-col justify-start items-center gap-0.5 flex">
              <div className={`self-stretch text-center ${page > 1 ? 'text-violet-700' : 'text-slate-700'} text-base font-semibold leading-normal`}>
                Education Background
              </div>
            </div>
          </div>
          <div className="w-60 flex-col justify-start items-center gap-4 inline-flex">
            <div className="w-10 h-10 bg-purple-50 rounded-[20px] shadow justify-center items-center inline-flex border-2 border-purple-50">
              <div className={`grow shrink basis-0 self-stretch py-2 bg-purple-50 rounded-[20px] border-2 ${page > 2 ? "border-violet-500" : "border-slate-700"} justify-center items-center inline-flex`}>
                <div className={`text-center ${page > 2 ? "text-violet-700" : "text-slate-700"} text-base font-semibold leading-normal`}>
                  3
                </div>
              </div>
            </div>
            <div className="self-stretch h-8 pt-2 flex-col justify-start items-center gap-0.5 flex">
              <div className={`self-stretch text-center ${page > 2 ? 'text-violet-700' : 'text-slate-700'} text-base font-semibold leading-normal`}>
                Specializations
              </div>
            </div>
          </div>
          <div className="w-60 flex-col justify-start items-center gap-4 inline-flex">
            <div className="w-10 h-10 bg-purple-50 rounded-[20px] shadow justify-center items-center inline-flex border-2 border-purple-50">
              <div className={`grow shrink basis-0 self-stretch py-2 bg-purple-50 rounded-[20px] border-2 ${page > 3 ? "border-violet-500" : "border-slate-700"} justify-center items-center inline-flex`}>
                <div className={`text-center ${page > 3 ? "text-violet-700" : "text-slate-700"} text-base font-semibold leading-normal`}>
                  4
                </div>
              </div>
            </div>
            <div className="self-stretch h-8 pt-2 flex-col justify-start items-center gap-0.5 flex">
              <div className={`self-stretch text-center ${page > 3 ? 'text-violet-700' : 'text-slate-700'} text-base font-semibold leading-normal`}>
                Experience
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressSteps4;
