import API from './config';

export const createArticle = async(article) => {
  try {
    const res = await API.post('api/articles', article, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateArticle = async(id, article) => {
  try {
    const res = await API.put(`api/articles/${id}`, article, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const getArticleById = async(id) => {
  try {
    const res = await API.get(`api/articles/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

// dashboard

export const getAllArticles = async() => {
  try {
    const res = await API.get(`api/articles`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const toggleArticleActive = async(id) => {
  try {
    const res = await API.put(`api/articles/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const deleteArticle = async(id) => {
  try {
    const res = await API.delete(`api/articles/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}