import API from './config';

// last section

export const addStudent = async(student) => {
  const res = await API.post('student/add', student);
  try {
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editStudent = async(id, student) => {
  try {
    const res = await API.patch(`student/edit/${id}`, student);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// page

export const getStudentById = async(id) => {
  try {
    const res = await API.get(`student/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}

// dashboard

export const getStudents = async() => {
  try {
    const res = await API.get('student/get');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`student/active/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const delStudent = async(id) => {
  try {
    await API.delete(`student/delete/${id}`);
  } catch (error) {
    console.error(error);
  }
};
