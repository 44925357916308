import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SideBar from "../Parts/SideBar2";
import searchIcon from "../../assets/search-lg.svg";
import FieldCol1 from "../Parts/FieldCol1";
import SubTopicResource from "../Parts/SubTopicResource";
import { getAllVideos } from "../../api/videoApi";
import { getProfessors } from "../../api/professorApi";
import { getAllPdfs } from "../../api/pdfApi";
import { getAllArticles } from "../../api/articleApi";
import { addTopic, editTopic, getTopicById } from "../../api/topicApi";
import { toast } from 'react-toastify';

const AddTopic = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [topicData, setTopicData] = useState({
    topicName: "",
    numberOfVideos: "",
    numberOfPDFs: "",
    numberOfPagesInPDFs: "",
    numberOfArticles: "",
    numberOfWordsInArticles: "",
    professorId: "",
    lengthOfVideosInMinutes: "",
    credits: "",
    pricing: "",
    description: "",
    resources: []
  });
  const [professors, setProfessors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredByType, setFilteredByType] = useState([]);
  const [selectedType, setSelectedType] = useState('video');

  const [allResources, setAllResources] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [resMap, setResMap] = useState({});

  const [allResourcesData, setAllResourcesData] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  // console.log(allResourcesData)
  // console.log(selectedResources)

  useEffect(() => {
    // Extract the relevant resources from `allResourcesData` based on selectedResources
    const extractedData = selectedResources.map(selected => {
      const resType = selected.type.charAt(0); // 'p', 'v', or 'a'
      return allResourcesData.find(resource => {
        const resourceData = resource[resType];
        return resourceData && (resourceData._id === selected.id || resourceData.pdfName === selected.name || resourceData.videoName === selected.name || resourceData.title === selected.name);
      });
    }).filter(Boolean); // Filter out any undefined values

    // Log the extracted data
    // console.log("Extracted Data:", extractedData);

    // Avoid setting the state if there are no changes
    if (extractedData.length > 0) {
      setTopicData(prev => ({
        ...prev,
        numberOfVideos: extractedData.filter(item => item.v).length || prev.numberOfVideos,
        numberOfPDFs: extractedData.filter(item => item.p).length || prev.numberOfPDFs,
        numberOfPagesInPDFs: extractedData.filter(item => item.p).reduce((sum, item) => sum + item.p.numberOfPages, 0) || prev.numberOfPagesInPDFs,
        numberOfArticles: extractedData.filter(item => item.a).length || prev.numberOfArticles,
        lengthOfVideosInMinutes: extractedData.filter(item => item.v).reduce((sum, item) => sum + item.v.lengthOfVideoInMinutes, 0) || prev.lengthOfVideosInMinutes,
      }));
    }
  }, [allResourcesData, selectedResources]);



  useEffect(() => {
    // initial data
    const fetchData = async () => {
      try {
        const [profs, pdfs, articles, videos] = await Promise.all([
          getProfessors(), getAllPdfs(), getAllArticles(), getAllVideos()
        ]);

        setProfessors(profs.map(p => ({ _id: p._id, professorName: p.info.name })));

        const all = [
          ...pdfs.map(p => ({ id: p._id, name: p.pdfName, type: "pdf", available: true, ranking: "0" })),
          ...videos.map(v => ({ id: v._id, name: v.videoName, type: "video", available: true, ranking: "0" })),
          ...articles.map(a => ({ id: a._id, name: a.title, type: "article", available: true, ranking: "0" }))
        ];
        const allData = [
          ...pdfs.map(p => ({ p })),
          ...videos.map(v => ({ v })),
          ...articles.map(a => ({ a }))
        ];
        setAllResources(all);
        setAllResourcesData(allData)

        // topic exists
        if (id) {
          const { data } = await getTopicById(id);
          setTopicData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    // filtering
    if (allResources.length) {
      let filteredRes = [...allResources];

      if (topicData.resources?.length) {
        filteredRes = filteredRes.filter(res =>
          !topicData.resources.some(selected =>
            selected['pdf'] === res.id ||
            selected['article'] === res.id ||
            selected['video'] === res.id
          )
        )
      };

      filteredRes = filteredRes.filter(r => r.type === selectedType);
      setFilteredByType(filteredRes);
    }
  }, [allResources, topicData.resources, selectedType])

  useEffect(() => {
    // map for quick lookup
    const map = allResources.reduce((acc, res) => {
      const { id, ...details } = res;
      acc[res.id] = details;
      return acc;
    }, {});

    setResMap(map);
  }, [allResources])

  useEffect(() => {
    // filter out all selected ones
    const updated = allResources?.filter(res =>
      !topicData.resources.some(selected =>
        selected.video === res.id || selected.pdf === res.id || selected.article === res.id
      )
    );

    setSelectedResources(allResources?.filter(res =>
      topicData.resources.some(selected =>
        selected.video === res.id || selected.pdf === res.id || selected.article === res.id
      )
    ))
    setFilteredData(updated);
  }, [topicData.resources, allResources])

  useEffect(() => {
    // if type changes, change data in modal
    setFilteredByType(filteredData.filter(r => r.type === selectedType));
  }, [filteredData, selectedType])

  useEffect(() => {
    // search
    let filtered = filteredData;
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredData(filtered.slice(0, 10));
  }, [searchTerm]);

  const handleInput = e => {
    const { name, value } = e.target;
    setTopicData(prev => ({ ...prev, [name]: value }))
  };

  // set data with prof id, name with prof name
  const handleProfessorsArray = e => {
    const { name } = e.target;
    setTopicData({ ...topicData, professorId: name });
  }

  const profNameValue = () => {
    const filtered = professors.filter(p => p._id === topicData.professorId);
    return filtered.length ? `${filtered[0].professorName.first} ${filtered[0].professorName.last}` : '';
  };

  const handleListSubmit = () => {
    // add 1, keep remaining 2 empty
    const newRes = filteredData.filter(t => !t.available);

    const resStruct = newRes.map(res => {
      const { id, type, ranking } = res;
      return { [type]: id, ranking };
    });

    setTopicData(prev => ({
      ...prev, resources: [...prev.resources, ...resStruct]
    }));

    setFilteredData(prev => prev.filter(t => t.available));
  };

  const handleTopicSubmit = async () => {
    if (!topicData.topicName) {
      toast.error("Please fill the required field: Topic Name.");
      return;
    }

    if (!topicData.numberOfVideos) {
      toast.error("Please fill the required field: Number of Videos.");
      return;
    }

    if (!topicData.numberOfPDFs) {
      toast.error("Please fill the required field: Number of PDFs.");
      return;
    }

    if (!topicData.numberOfPagesInPDFs) {
      toast.error("Please fill the required field: Number of Pages in PDFs.");
      return;
    }

    if (!topicData.description) {
      toast.error("Please fill the required field: Description.");
      return;
    }

    if (!topicData.professorId) {
      toast.error("Please select a Professor.");
      return;
    }

    if (!topicData.resources || topicData.resources.length === 0) {
      toast.error("Please add at least one resource.");
      return;
    }

    for (let i = 0; i < topicData.resources.length; i++) {
      if (!topicData.resources[i].ranking || topicData.resources[i].ranking === "0") {
        toast.error("Please ensure that each resource has a valid ranking.");
        return;
      }
    }

    try {
      // api calls
      const res = id
        ? await editTopic(id, topicData)
        : await addTopic(topicData);
      // console.log("final",res);

      if (res) {
        toast.success(id ? "Topic updated successfully." : "Topic added successfully.");
        nav('/dashboard/topic');
      } else {
        toast.error("Error, please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the data.");
    }
  };

  return (
    <div className="bg-purple-50">

      <div className="justify-start items-start inline-flex">
        <SideBar />
        <div className="grow ml-[15.5vw] shrink basis-0 self-stretch justify-start items-start flex">
          <div className="grow shrink basis-0 self-stretch justify-start items-start flex">
            <div className="grow shrink basis-0 h-[1650px] pt-4 pb-24 justify-start items-center gap-16 flex">
              <div className="grow shrink basis-0 h-[1538px] px-8 justify-center items-start flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                  <div className="self-stretch h-[1538px] flex-col justify-start items-start gap-8 flex">
                    <div className="self-stretch h-[1538px] flex-col justify-start items-start gap-6 flex">
                      <div className="self-stretch justify-start items-start gap-8 inline-flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                            <div className="text-zinc-700 text-4xl font-bold font-['Inter'] leading-tight">
                              Add Topic
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch justify-start !flex-wrap items-start gap-6 flex">
                        <div className="h-[70px] justify-start items-start gap-8 flex">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="topicName"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Topic Name<span className="text-red-500">*</span>
                              </label>
                              <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                  <input
                                    id="topicName"
                                    type="text"
                                    value={topicData.topicName}
                                    name="topicName"
                                    onChange={handleInput}
                                    className="grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="noOfVideo"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Number of videos<span className="text-red-500">*</span>
                              </label>
                              <div className=" flex-col justify-start items-start flex">
                                <input
                                  id="noOfVideo"
                                  type="number"
                                  value={topicData.numberOfVideos}
                                  name="numberOfVideos"
                                  onChange={handleInput}
                                  className="h-11 w-[140px] 3xl:w-[165px] px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="noOfPdf"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Number of pdfs<span className="text-red-500">*</span>
                              </label>
                              <div className=" flex-col justify-start items-start flex">
                                <input
                                  id="noOfPdf"
                                  type="number"
                                  name="numberOfPDFs"
                                  value={topicData.numberOfPDFs}
                                  onChange={handleInput}
                                  className="h-11 w-[120px] 3xl:w-[165px] px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="noOfPages"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Number of pages in pdfs<span className="text-red-500">*</span>
                              </label>
                              <div className="self-stretch h-11 flex-col justify-start items-start flex">
                                <input
                                  id="noOfPages"
                                  type="number"
                                  name="numberOfPagesInPDFs"
                                  value={topicData.numberOfPagesInPDFs}
                                  onChange={handleInput}
                                  className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="noOfArticle"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Number of articles
                              </label>
                              <div className="w-[156px] h-11 flex-col justify-start items-start flex">
                                <input
                                  id="noOfArticle"
                                  type="number"
                                  name="numberOfArticles"
                                  value={topicData.numberOfArticles}
                                  onChange={handleInput}
                                  className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="noOfWords"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Number of words in Articles
                              </label>
                              <div className="self-stretch h-11 flex-col justify-start items-start flex">
                                <input
                                  id="noOfWords"
                                  type="number"
                                  name="numberOfWordsInArticles"
                                  value={topicData.numberOfWordsInArticles}
                                  onChange={handleInput}
                                  className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch justify-start items-start gap-6 inline-flex">
                        <div className="h-[70px] justify-start items-start gap-8 flex">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="profName"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Professor Name
                              </label>
                              <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                  <input
                                    id="profName"
                                    type="text"
                                    placeholder="Manthan"
                                    name="professorId"
                                    value={profNameValue()}
                                    // onChange={handleInput}
                                    readOnly
                                    className=" w-[500px] grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none pointer-events-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="len"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Length of videos in minutes
                              </label>
                              <div className=" h-11 flex-col justify-start items-start flex">
                                <input
                                  id="len"
                                  type="number"
                                  name="lengthOfVideosInMinutes"
                                  value={topicData.lengthOfVideosInMinutes}
                                  onChange={handleInput}
                                  className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="justify-start items-start gap-8 flex">
                          <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                              <label
                                htmlFor="credits"
                                className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                              >
                                Credits
                              </label>
                              <div className="w-[117px] flex-col justify-start items-start flex">
                                <input
                                  id="credits"
                                  type="text"
                                  name="credits"
                                  value={topicData.credits}
                                  onChange={handleInput}
                                  className="h-11 w-[140px] px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="pricing"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] ml-6 leading-tight"
                            >
                              Pricing
                            </label>
                            <div className=" flex-col justify-start items-start flex">
                              <input
                                id="pricing"
                                type="text"
                                name="pricing"
                                value={topicData.pricing}
                                onChange={handleInput}
                                className="h-11 w-[140px] ml-6 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <FieldCol1
                        fieldName={"Description"}
                        fieldPlace={"This contains XXX..."}
                        name={"description"}
                        required
                        value={topicData.description}
                        handleInput={handleInput}
                      />
                      <div className="h-[166px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight">
                          Professor Name
                        </div>
                        <div className="w-[77vw] h-[140px] px-3.5 py-[3px] bg-white rounded-lg border border-gray-900 grid grid-cols-4 gap-3 overflow-y-auto">
                          {professors.map((prof, i) => (
                            <label key={i} className="flex items-center gap-2">
                              <input
                                type="radio"
                                name={prof._id}
                                checked={topicData.professorId === prof._id || false}
                                onChange={handleProfessorsArray}
                                className=" h-4 w-5 text-blue-600"
                              />
                              {`${prof.professorName.first} ${prof.professorName.last}`}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className=" self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className=" self-stretch flex-col justify-start items-start gap-1 flex">
                          <div className="self-stretch px-6 pt-5 justify-start items-start gap-4 inline-flex">
                            <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex">
                              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                                <div className="text-gray-900 text-2xl font-semibold font-['Inter'] leading-7">
                                  List of Resources
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" w-[90%] h-[216px] p-6 bg-purple-100 rounded-lg justify-start items-start gap-4 flex flex-row overflow-x-auto overflow-y-hidden">
                            {topicData.resources?.map((res, i) => {
                              // {pdf: id, ranking: 0}
                              const resType = Object.keys(res)[0];
                              const id = res[resType];
                              const resource = resMap[id];

                              return (
                                <div key={i} className="flex-shrink-0">
                                  <SubTopicResource
                                    headingName={resource?.name}
                                    typeOfFile={resType}
                                    ranking={res.ranking}
                                    handleRanking={(e) => {
                                      // change ranking
                                      setTopicData(prev => ({
                                        ...prev,
                                        resources: prev.resources.map(r =>
                                          r[resType] === id ? { ...r, ranking: e } : r
                                        )
                                      }));
                                    }}
                                    handleRemove={() => {
                                      // remove the resource
                                      setTopicData(prev => ({
                                        ...prev,
                                        resources: prev.resources.filter(r => r[resType] !== id)
                                      }))
                                      // add in filtered by type
                                      setFilteredByType(prev => [
                                        ...prev,
                                        { id, name: resource.name, type: resType, available: true, ranking: res.ranking }
                                      ])
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="w-[926px] h-[486px] relative bg-white">
                        <div className="left-[24px] top-[90px] absolute border-r border-black flex-col justify-start items-start inline-flex">
                          <button onClick={() => setSelectedType('video')} className=" self-stretch p-2.5 hover:bg-purple-100 rounded-lg justify-start items-center gap-2 inline-flex">
                            <p className="text-black text-base font-semibold font-['Source Sans Pro']">
                              Video
                            </p>
                          </button>
                          <button onClick={() => setSelectedType('pdf')} className=" hover:bg-purple-100 rounded-lg self-stretch p-2 justify-start items-center gap-2.5 inline-flex">
                            <p className="text-black text-base font-semibold font-['Source Sans Pro']">
                              PDF
                            </p>
                          </button>
                          <button onClick={() => setSelectedType('article')} className=" hover:bg-purple-100 rounded-lg self-stretch p-2 justify-start items-center gap-2.5 inline-flex">
                            <p className="text-black text-base font-semibold font-['Source Sans Pro']">
                              Article
                            </p>
                          </button>
                        </div>

                        <div>
                          <div className="left-[199px] top-[24px] absolute justify-start items-center gap-3 inline-flex">
                            <div className="w-[400px] flex-col justify-start items-start gap-2 inline-flex">
                              <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                    <img src={searchIcon} alt="search" className="w-5 h-5 relative" />
                                    <input
                                      type="text"
                                      className="w-full h-full text-gray-500 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                      placeholder="Search"
                                      value={searchTerm || ''}
                                      onChange={(e) => {
                                        setSearchTerm(e.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="w-[730px] left-[137px] top-[90px] absolute justify-start items-start gap-4 grid grid-cols-7">
                            {filteredByType?.map((item, index) => (
                              <div
                                key={index}
                                className={`flex-col justify-start items-center gap-2.5 inline-flex cursor-pointer`}
                                onClick={() => {
                                  // toggle availability if file is selected.
                                  const updated = filteredData.map(file =>
                                    file.id === item.id ? { ...file, available: !file.available } : file
                                  );
                                  setFilteredData(updated);
                                }}
                                role="button"
                                // aria-pressed={selectedId === item._id}
                                tabIndex={0}
                              >
                                <div className={`w-24 h-[72px] bg-zinc-300 rounded-lg ${item.available === false ? 'border-2 border-blue-500' : ''}`}></div>
                                <p className="text-black text-sm font-semibold font-['Source Sans Pro'] truncate w-24" title={item.name}>
                                  {item.name}
                                </p>
                              </div>
                            ))}
                          </div>
                          <button
                            className="w-[730px] px-4 py-2.5 left-[123px] top-[332px] absolute bg-amber-500 rounded-lg shadow justify-center items-center gap-2 inline-flex"
                            onClick={handleListSubmit}
                          >
                            <p className="text-white text-sm font-semibold font-['Inter'] leading-tight">
                              Add to List
                            </p>
                          </button>
                        </div>
                      </div>

                      <div className=" self-stretch h-12 flex-col justify-start items-start gap-4 flex">
                        <button onClick={handleTopicSubmit} className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex">
                          <p className="text-white text-base font-semibold font-['Inter'] leading-normal">
                            Submit
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AddTopic;
