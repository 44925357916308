import React from "react";

const ProgressSteps = ({ind}) => {
  const arr = [
    "Overview",
    "Campus Details",
    "Course Details",
    "Placement Statistics",
    "Gallery & Video",
    "Frequently Asked Questions",
  ];

  return (
    <div className="flex-col justify-center items-center inline-flex scale-[0.87] ml-[5vw]">
      <div className=" h-[114px] relative">

        {/* Lines between numbers */}
        <div className="w-[1120px] h-0.5 left-[-56px] top-[19px] absolute">
          {ind === 1 ? (
            <>
              <div className="w-[245px] h-0.5 left-0 top-0 absolute bg-violet-500" />
              <div className="w-[608px] h-0.5 left-[256px] top-0 absolute bg-gray-200" />
              <div className="w-[608px] h-0.5 left-[612px] top-0 absolute bg-gray-200" />
            </>
          ) : ind === 2 ? (
            <>
              <div className="w-[480px] h-0.5 left-0 top-0 absolute bg-violet-500" />
              <div className="w-[694px] h-0.5 left-[512px] top-0 absolute bg-gray-200" />
            </>
          ) : ind === 3 ? (
            <>
              <div className="w-[608px] h-0.5 left-[612px] top-0 absolute bg-gray-200" />
              <div className="w-[720px] h-0.5 left-0 top-0 absolute bg-violet-500" />
            </>
          ) : ind === 4 ? (
            <>
              <div className="w-[608px] h-0.5 left-[256px] top-0 absolute bg-gray-200" />
              <div className="w-[608px] h-0.5 left-[612px] top-0 absolute bg-gray-200" />
              <div className="w-[960px] h-0.5 left-0 top-0 absolute bg-violet-500" />
            </>
          ) : ind === 5 ? (
            <>
              <div className="w-[608px] h-0.5 left-[256px] top-0 absolute bg-gray-200" />
              <div className="w-[608px] h-0.5 left-[612px] top-0 absolute bg-gray-200" />
              <div className="w-[1200px] h-0.5 left-0 top-0 absolute bg-violet-500" />
            </>
          ) : (
            ind === 6 && (
              <>
                <div className="w-[608px] h-0.5 left-[256px] top-0 absolute bg-gray-200" />
                <div className="w-[608px] h-0.5 left-[612px] top-0 absolute bg-gray-200" />
                <div className="w-[1200px] h-0.5 left-0 top-0 absolute bg-violet-500" />
              </>
            )
          )}
        </div>

        <div className="left-[-156px] top-0 absolute justify-start items-start inline-flex">
          {arr.map((each, i) => (
            <div
              key={i}
              className="w-60 flex-col justify-start items-center gap-4 inline-flex">
              <div
                className="w-10 h-10 bg-purple-50 rounded-[20px] justify-center items-center inline-flex border-2 border-purple-50">
                <div
                  className={`grow shrink basis-0 self-stretch py-2 bg-purple-50 rounded-[20px] border-2 ${
                    ind > i ? "border-violet-500" : "border-slate-700"
                  } justify-center items-center inline-flex`}>
                  <p
                    className={`text-center ${
                      ind > i ? "text-violet-700" : "text-slate-700"
                    } text-base font-semibold   leading-normal`}>
                    {i + 1}
                  </p>
                </div>
              </div>
              <div className="self-stretch h-8 pt-2 flex-col justify-start items-center gap-0.5 flex">
                <div
                  className={`self-stretch text-center ${
                    ind > i ? "text-violet-700" : "text-slate-700"
                  } text-base font-semibold   leading-normal`}>
                  {each}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressSteps;
