import API from './config';

export const addEntry = async (field, name,logo) => {
    try {
        const res = await API.post(`api/config/${field}`, { field, name, logo }, {
            headers: { "Content-Type": "application/json" }
        });
        return res.data;
    } catch (error) {
        console.error("Failed to add entry:", error);
        throw error;
    }
};

export const getAllEntries = async (field) => {
    try {
        const res = await API.get(`api/config/${field}`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getEntryById = async (field, id) => {
    try {
        const res = await API.get(`api/config/${field}/${id}`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteEntryById = async (field, id) => {
    try {
        const res = await API.delete(`api/config/${field}/${id}`);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
