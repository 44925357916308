import React, { useEffect, useMemo, useRef, useState } from "react";
import arrow from "../../assets/chevron-down.svg";

const RefDropDown = ({ fieldName, array, handleInput, currentValue, display, theValue,required }) => {
  const dropdownRef = useRef(null); 

  // memoize and sort array by email
  const sortedArray = useMemo(() => 
    [...array].sort((a, b) => a[display].localeCompare(b[display])), 
    [array]
  );

  // console.log("sorted: ",sortedArray);
  // console.log("currentValue data: ",currentValue);

  const currentDisplay = useMemo(() => {
    const item = sortedArray.find(item => item[theValue] === currentValue);
    return item ? item[display] : "";
  }, [sortedArray, currentValue, display, theValue]);

  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(currentDisplay);

  useEffect(() => {
    setInputValue(currentDisplay);
  },[currentDisplay]);

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    // Attach event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedValue) => {
    const chosenItem = sortedArray.find(item => item[display] === selectedValue);

    setInputValue(selectedValue);
    setShowOptions(false);

    // handleInput({target: {name: "userId", value: chosenItem._id}});
    // if email, store the email else store creator's ref
    handleInput({target: { id: display, name: display, value: chosenItem[display === "email" ? display : theValue]}})
  }

  return (
    <div ref={dropdownRef} className="flex flex-col mt-1.5">
      <label htmlFor={fieldName} className="w-[150px] text-zinc-700 text-base font-semibold leading-tight">
        {fieldName}{required&&<span className="text-red-500">*</span>}
      </label>
      <div className="w-[310px] bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
        <p className="w-full px-3.5 py-2.5 text-gray-700 focus:outline-none truncate"
          title={inputValue}>
          {inputValue}
        </p>
        <button
          type="button"
          className="px-3.5 py-2.5 hover:bg-violet-50"
          onClick={() => setShowOptions(!showOptions)}>
          <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
        </button>
      </div>
      {showOptions && (
        <div className=" w-[310px] z-10 mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg overflow-x-auto h-96">
          {sortedArray.map((field, index) => (
            <p key={index}
              className="px-3.5 py-2.5 hover:bg-violet-50 cursor-pointer truncate"
              title={field[display]}
              onClick={() => handleSelect(field[display])}>
              {field[display]}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default RefDropDown;
