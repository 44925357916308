import API from './config';

// course last section

export const addCourse = async(course) => {
  try {
    const res = await API.post('api/course/', course);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editCourse = async(id, course) => {
  try {
    const res = await API.put(`api/course/${id}`, course);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// course dashboard

export const getCourseById = async(id) => {
  try {
    const res = await API.get(`api/course/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getCourses = async() => {  
  try {
    const res = await API.get('api/course/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`api/course/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delCourse = async(id) => {
  try {
    await API.delete(`api/course/${id}`);
  } catch (error) {
    console.error(error);
  }
};
