import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfessorEducationBackground, selectProfessor } from "../../redux/professorSlice";
import SideBar from "../Parts/SideBar2";
import FieldCol1 from "../Parts/FieldCol1";
import arrow from '../../assets/chevron-down.svg';
import ProgressSteps4 from "./progressBar";
import { toast } from 'react-toastify';

const years = Array.from({ length: 2024 - 1975 + 1 }, (_, i) => 1975 + i);

const EduBg = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const educationBackground = useSelector((state) => state.professor.professor.education);
  const [localData, setLocalData] = useState(educationBackground);
  const [showYearDropdown, setShowYearDropdown] = useState(Array(educationBackground.length).fill(false));

  const sectionRef = useRef(educationBackground);
  const dropdownRefs = useRef([]);

  const professorData = useSelector(selectProfessor) || null

  useEffect(() => {
    if (professorData.education) {
      setLocalData(professorData.education);
    } else {
      setLocalData(educationBackground);
    }
  }, [educationBackground, professorData.education]);

  useEffect(() => {
    dispatch(updateProfessorEducationBackground(sectionRef.current));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowYearDropdown(showYearDropdown.map(() => false));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showYearDropdown]);


  const handleData = (index, key, value) => {
    const updatedData = [...localData];
    updatedData[index] = { ...updatedData[index], [key]: value };
    setLocalData(updatedData);
    sectionRef.current = updatedData
  };

  const addCollege = () => {
    const newCollege = {
      name: '',
      degree: '',
      field: '',
      gradYear: '',
      profileDesc: ''
    };
    const updatedData = [...localData, newCollege];
    setLocalData(updatedData);
    sectionRef.current = updatedData
    setShowYearDropdown([...showYearDropdown, false]);
  };

  const removeCollege = (ind) => {
    const newCol = localData.filter((_, i) => i !== ind)
    setLocalData(newCol)
    sectionRef.current = newCol;
  }

  const toggleYearDropdown = (index) => {
    const updatedDropdowns = showYearDropdown.map((show, i) => (i === index ? !show : show));
    setShowYearDropdown(updatedDropdowns);
  };

  const handleYearSelect = (index, year) => {
    handleData(index, 'gradYear', year.toString());
    toggleYearDropdown(index);
  };

  const handleSubmit = () => {
    if (!Array.isArray(localData) || localData.length === 0) {
      toast.error("Please add at least one education entry.");
      return false; // Prevents submission if no education entries
    }

    for (const entry of localData) {
      if (
        !entry.name ||
        !entry.degree ||
        !entry.field ||
        !entry.gradYear ||
        !entry.profileDesc
      ) {
        toast.error("Please fill in all the required fields for each education entry.");
        return false; // Prevents submission if any entry is invalid
      }
    }

    // If validation passes
    sectionRef.current = localData;

    // Dispatch the action to update the professor's education background
    dispatch(updateProfessorEducationBackground(localData));

    return true
  };


  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));


  return (
    <div className="bg-purple-50 justify-start items-start inline-flex">
      <SideBar />

      <div className="grow shrink ml-[15.5vw] basis-0 self-stretch justify-start items-start inline-flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">

              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch  flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="2" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Education Background
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-purple-100 self-stretch flex-col justify-start items-start gap-6 flex py-3.5 px-3.5">
                    {localData.map((edu, index) => (
                      <React.Fragment key={index}>
                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                          <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                <label
                                  htmlFor={`name${index}`}
                                  className="text-zinc-700 text-base font-semibold leading-tight"
                                >
                                  College/School Name<span className="text-red-500">*</span>
                                </label>
                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                    <input
                                      id={`name${index}`}
                                      type="text"
                                      placeholder="'College Name'"
                                      className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                      value={edu.name}
                                      onChange={(e) => handleData(index, 'name', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                          <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="self-stretch w-[611px] h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                <label
                                  htmlFor={`degree${index}`}
                                  className="text-zinc-700 text-base font-semibold leading-tight"
                                >
                                  Degree<span className="text-red-500">*</span>
                                </label>
                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                    <input
                                      id={`degree${index}`}
                                      type="text"
                                      placeholder="'B.Tech'"
                                      className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                      value={edu.degree}
                                      onChange={(e) => handleData(index, 'degree', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                <label
                                  htmlFor={`field${index}`}
                                  className="text-zinc-700 text-base font-semibold leading-tight"
                                >
                                  Field of Study<span className="text-red-500">*</span>
                                </label>
                                <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                  <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                    <input
                                      id={`field${index}`}
                                      type="text"
                                      placeholder="'CSE'"
                                      className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                      value={edu.field}
                                      onChange={(e) => handleData(index, 'field', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-8 flex">
                            <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="w-[187px] h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                                <label htmlFor={`gradYear${index}`} className="text-zinc-700 text-base font-semibold leading-tight">
                                  Year of Graduation<span className="text-red-500">*</span>
                                </label>

                                <div className="self-stretch bg-white hover:bg-violet-50 rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                  <div ref={el => dropdownRefs.current[index] = el} className="group w-full relative">
                                    <button
                                      type="button"
                                      className="inline-flex px-3.5 py-2.5 text-gray-400 justify-between items-center w-full text-md font-medium focus:outline-none focus:bg-violet-200"
                                      onClick={() => toggleYearDropdown(index)}
                                    >
                                      <p>{edu.gradYear ? new Date(edu.gradYear).getFullYear() : "Select your year"}</p>

                                      <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
                                    </button>
                                    {showYearDropdown[index] && (
                                      <div className="absolute left-0 z-10 text-center w-full mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg transition duration-300">
                                        <div className="py-1 max-h-40 overflow-x-auto">
                                          {years.map((year) => (
                                            <p
                                              key={year}
                                              className="m-2 cursor-pointer"
                                              onClick={() => handleYearSelect(index, year)}
                                            >
                                              {year}
                                            </p>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <FieldCol1
                          fieldName="Profile Description"
                          fieldPlace="I have experience XXX …."
                          name="profileDesc"
                          required
                          value={edu.profileDesc}
                          handleInput={(e) => handleData(index, 'profileDesc', e.target.value)}
                        />
                        <p className="cursor-pointer text-violet-700 text-base font-semibold leading-tight" onClick={() => removeCollege(index)}>Remove</p>
                      </React.Fragment>
                    ))}
                  </div>
                  <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={addCollege}>
                    + Add college/school
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EduBg;
