import API from './config';

// price last section

export const addPrice = async(price) => {
  try {
    const res = await API.post('price/create', price);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editPrice = async(id, price) => {
  try {
    const res = await API.put(`price/${id}`, price);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// price dashboard

export const getPriceById = async(id) => {
  try {
    const res = await API.get(`price/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
}


export const getPrices = async() => {  
  try {
    const res = await API.get('price/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const deletePrice = async(id) => {
  try {
    await API.delete(`price/${id}`);
  } catch (error) {
    console.error(error);
  }
};
