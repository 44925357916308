import API from './config';

// last section

export const addCollege = async(college) => {
  try {
    const res = await API.post('college/add', college);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editCollege = async(id, college) => {
  try {
    const res = await API.patch(`college/edit/${id}`, college);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// page

export const getCollegeById = async(id) => {
  try {
    const res = await API.get(`college/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}

// dashboard

export const getColleges = async() => {
  try {
    const res = await API.get('college/get');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`college/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const delCollege = async(id) => {
  try {
    await API.delete(`college/delete/${id}`);
  } catch (error) {
    console.error(error);
  }
};
