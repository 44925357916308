import API from './config';

// degree last section

export const addDegree = async(degree) => {
  try {
    const res = await API.post('/degree/', degree);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editDegree = async(id, degree) => {
  try {
    const res = await API.put(`/degree/${id}`, degree);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// degree dashboard

export const getDegreeById = async(id) => {
  try {
    const res = await API.get(`/degree/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getDegrees = async() => {
 
  try {
    const res = await API.get('/degree/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`degree/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delDegree = async(id) => {
  try {
    await API.delete(`/degree/${id}`);
  } catch (error) {
    console.error(error);
  }
};
