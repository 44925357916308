import React from "react";
import del from "../../assets/del.svg";
import pencil from "../../assets/pencil.svg";
import calendar from "../../assets/calendar.svg";

const DashboardCard = ({ name, code, date, isActive, edit, deletee, toggleActive }) => {
  return (
    <div className="border-2 p-4 rounded-lg relative flex justify-between flex-col">
      <div className="flex justify-between items-center mb-4">
        <button className="flex justify-start" onClick={toggleActive}>
          <span
            className={`text-[0.9rem] h-[25px] w-[70px] flex justify-center rounded-full text-black ${isActive ? "bg-green-300" : "bg-red-500"
              }`}
          >
            {isActive ? `Active` : "Inactive"}
          </span>
          <p className="text-md ml-4 mb-1">{isActive ? "|  |" : "▶"}</p>
        </button>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">{name}</h3>
        <p>{code}</p>
        <div className="flex mt-2">
          <img src={calendar} alt="Calender Icon" />
          <span className="text-sm ml-2">{date}</span>
        </div>
      </div>
      <div className="flex space-x-2 mb-0 pb-0">
        <button className="w-[50%] border-2 flex px-4 py-2 rounded-full m-1 bg-white text-red-500 border-red-500 justify-center items-center" onClick={deletee}>
          <img src={del} alt="Delete Icon" />
          <span className="ml-4 mt-0">Delete</span>
        </button>

        <button className="w-[50%] border-2 flex px-4 py-2 rounded-full m-1 bg-white border-black text-stone justify-center items-center" onClick={edit}>
          <img src={pencil} alt="Edit Icon" />
          <span className="ml-[20%]">Edit</span>
        </button>
      </div>
    </div>
  );
};

export default DashboardCard;