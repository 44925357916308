import API from './config';

// semester last section

export const addSemester = async(semester) => {
  try {
    const res = await API.post('/semester/', semester);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editSemester = async(id, semester) => {
  try {
    const res = await API.put(`/semester/${id}`, semester);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// semester dashboard

export const getSemesterById = async(id) => {
  try {
    const res = await API.get(`/semester/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getSemesters = async() => {
 
  try {
    const res = await API.get('/semester/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`semester/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delSemester = async(id) => {
  try {
    await API.delete(`/semester/${id}`);
  } catch (error) {
    console.error(error);
  }
};
