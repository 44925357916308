import API from './config';

// university last section

export const addUniversity = async(university) => {
  try {
    const res = await API.post('university/add', university);
    return res.data;
  } 
  // catch (error) {
  //   console.error(error);
  // }
  catch (error) {
    if (error.response) {
      // Server responded with a status code that falls out of the range of 2xx
      const status = error.response.status;
      const message = error.response.data?.message || "An error occurred.";

      console.error(`Error ${status}: ${message}`);

      // You can provide more specific error messages based on status code
      if (status === 400) {
        throw new Error("Bad request. Please check the input fields-->.");
      } else if (status === 401) {
        throw new Error("Unauthorized. Please log in again.");
      } else if (status === 403) {
        throw new Error("Forbidden. You don't have permission for this action.");
      } else if (status === 500) {
        throw new Error("Server error. Please try again later.");
      } else {
        throw new Error(message);
      }
    } else if (error.request) {
      // Request was made but no response was received
      console.error("No response from the server.", error.request);
      throw new Error("No response from the server. Please try again later.");
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error setting up the request:", error.message);
      throw new Error("Failed to send the request. Please try again.");
    }
  }
}

export const editUniversity = async(id, university) => {
  try {
    const res = await API.patch(`university/edit/${id}`, university);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// university dashboard

export const getUniversityById = async(id) => {
  try {
    const res = await API.get(`university/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getUniversities = async() => {
  try {
    const res = await API.get('university/all');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`university/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delUniversity = async(id) => {
  try {
    await API.delete(`university/delete/${id}`);
  } catch (error) {
    console.error(error);
  }
};
