import API from "./config";

// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await API.post('user/signup', userData);
    return { data: response.data };
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

// Check user credentials for login
export const checkUser = async (loginInfo) => {
  try {
    const response = await API.post('user/login', loginInfo);
    return { data: response.data };
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

export const getAllUsers = async() => {
  try {
    const response = await API.get('user/all');
    return response.data;
  } catch (error) {
    console.error("Error getting list of users: ", error);
    throw error;
  }
}

// Get user by userId
export const getUserById = async(id) => {
  try {
    const res = await API.get(`user/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

// Update user by userId
export const updateUserById =async(info) => {
  try {
    const res = await API.put(`user`, info)
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Get user by its id
export const getUserByOwnId = async(id) => {
  try {
    const res = await API.get(`user/user/${id}`)
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

// Edit user by id
export const editUserByOwnId = async(id, info) => {
  try {
    const res = await API.patch(`user/user/${id}`, info);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

// Update user by email
export const updateUser = async (email) => {
  try {
    const response = await API.post('user/forgot-password', { email });
    return { data: response.data };
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// Reset user password
export const resetPassword = async (userData) => {
  try {
    const response = await API.post('user/reset-password', userData);
    return { data: response.data };
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const changePassword = async(data) => {
  try {
    const res = await API.post('user/change-password', data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`user/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

// Delete a user
export const deleteUser = async(id) => {
  try {
    const res = await API.delete(`user/user/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}