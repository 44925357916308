import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import SideBar from "../Parts/SideBar2";
import FieldCol2 from "../Parts/FieldCol2";
import FieldCol1 from "../Parts/FieldCol1";
import cloud from '../../assets/upload-cloud-02.svg'
import arrow from '../../assets/chevron-down.svg'
import cross from "../../assets/cross.svg";
import ProgressSteps4 from "./progressBar";
import Imagekit from "../Parts/Imagekit";
import { useDispatch, useSelector } from "react-redux";
import { selectProfessor, updateProfessorPersonalInfo, updateProfUserId } from "../../redux/professorSlice";
import { getAllUsers } from "../../api/authAPI";
import RefDropDown from "../Parts/RefDropDown";
import { getAllEntries } from "../../api/configurationAPI";
import DropDown from "../Parts/DropDown";
import { toast } from 'react-toastify';

const BasicInfo = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const professorData = useSelector(selectProfessor)
  const professorInfo = useSelector((state) => state.professor.professor.info);
  const [localData, setLocalData] = useState(professorInfo);
  const sectionRef = useRef(professorInfo);
  const dropdownRef = useRef(null);

  const [showStateOptions, setShowStateOptions] = useState(false);
  const [languages, setLanguages] = useState(professorInfo.languages || []);
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [users, setUsers] = useState([]);

  const [stateArray, setStateArray] = useState([]);
  const [languagesArray, setLanguagesArray] = useState([]);

  const fetchStateArray = async () => {
    try {
      const statesData = await getAllEntries('states');
      const languagesData = await getAllEntries('languages');
      if (languagesData.length > 0) {
        setLanguagesArray(languagesData.map(language => language.name));
      }
      if (statesData.length > 0) {
        setStateArray(statesData.map(state => state.name));
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  useEffect(() => {
    fetchStateArray();
  }, []);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        try {
          const res = await getAllUsers();
          setUsers(res);
        } catch (error) {
          console.error(error);
        }
      }
      fetchUsers();
    } catch (error) {
      console.error(error);
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowStateOptions(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    setLocalData(professorData.info);
  }, [professorData.info]);

  useEffect(() => {
    dispatch(updateProfessorPersonalInfo(sectionRef.current));
  }, [dispatch]);

  const handleData = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    if (name === "pincode" && value.length > 6) {
      return; // Exit if pincode exceeds 6 characters
    }

    let newLocal = { ...localData }, newSection = { ...sectionRef.current };

    if (keys.length > 1) {
      // Handling nested properties
      const [key, subKey] = keys;
      newLocal = { ...newLocal, [key]: { ...newLocal[key], [subKey]: value } };
      newSection = { ...newSection, [key]: { ...newSection[key], [subKey]: value } };
    } else {
      // Handling flat properties
      newLocal[name] = value;
      newSection[name] = value;
    }

    setLocalData(newLocal);
    sectionRef.current = newSection;
  };

  const handleMobileNumbersChange = (index, value) => {
    if (value.length > 10) {
      return; // Exit if the mobile number exceeds 10 characters
    }

    const updatedMobileNumbers = [...localData.mobile];
    updatedMobileNumbers[index] = value;
    const updated = { ...localData, mobile: updatedMobileNumbers };
    setLocalData(updated);
    sectionRef.current = updated
  };

  const addMobileNumber = () => {
    const updatedMobileNumbers = [...localData.mobile, ''];
    const updated = { ...localData, mobile: updatedMobileNumbers }

    setLocalData(updated)
    sectionRef.current = updated

  };

  const removeNumber = (ind) => {
    const newData = sectionRef.current.mobile.filter((_, i) => i !== ind)

    const updated = { ...sectionRef.current, mobile: newData }
    setLocalData(updated)
    sectionRef.current = updated
  }

  const handleStateChange = (state) => {
    const updated = { ...localData, state };
    setLocalData(updated);
    sectionRef.current = updated
    setShowStateOptions(false);
  };

  const handleLanguageChange = (index, value) => {
    if (!languages.includes(value)) {
      const updatedLanguages = [...languages, value];
      setLanguages(updatedLanguages);

      const updated = { ...localData, languages: updatedLanguages };
      setLocalData(updated);
      sectionRef.current = updated;
    }

    setShowOptions(false);
    setInputValue('');
  };


  const addLanguage = () => {
    const updatedLanguages = [...languages, ''];
    setLanguages(updatedLanguages);
    const data = { ...localData, languages: updatedLanguages };
    setLocalData(data);
    sectionRef.current = data;
  };

  const removeLang = (ind) => {
    const newLang = sectionRef.current.languages.filter((_, i) => i !== ind)

    const updated = { ...sectionRef.current, languages: newLang }
    setLocalData(updated);
    sectionRef.current = updated;
  }

  const handleSubmit = () => {
    if (
      !localData.profilePhoto ||
      !localData.name.first ||
      !localData.desc ||
      !localData.email ||
      !localData.address ||
      !localData.landmark ||
      !localData.pincode ||
      !localData.city ||
      !localData.district ||
      !localData.state ||
      (localData.phoneNumber && !/^\d{10}$/.test(localData.phoneNumber))
    ) {
      toast.error(localData.phoneNumber ? "Phone number must be exactly 10 digits." : "Please fill in all the required fields.");
      return false; // Indicate that the form is not valid
    }

    if (!/^\d+$/.test(localData.pincode)) {
      toast.error("Pincode must be a valid number.");
      return false; // Indicate that the form is not valid
    }

    sectionRef.current = localData;

    dispatch(updateProfessorPersonalInfo(localData));
    const chosenUser = users.find(user => user.email === sectionRef.current.email);
    if (chosenUser) dispatch(updateProfUserId(chosenUser._id));

    return true; // Indicate that the form is valid
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="bg-purple-50 justify-start items-start inline-flex">
      <SideBar />

      <div className=" ml-[15.5vw] grow shrink basis-0 self-stretch bg-purple-50 justify-start items-start inline-flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">

              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="1" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold   leading-tight">
                          Basic Info
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <label htmlFor="profilePic" className="w-[512px] cursor-pointer flex-col justify-start items-start gap-4 inline-flex">
                      <Imagekit
                        id={"profilePic"}
                        onSuccess={(res) => {
                          const updated = {
                            ...sectionRef.current,
                            profilePhoto: res.url,
                          };
                          sectionRef.current = updated;
                          setLocalData({ ...localData, profilePhoto: updated.profilePhoto })
                        }}
                      />
                      <div className="self-stretch h-[126px] px-6 py-4 bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                        <div className="self-stretch h-full justify-around items-center gap-3 flex flex-row-reverse">
                          <div className={`w-20 h-20 rounded-full overflow-hidden ${sectionRef.current.profilePhoto ? "" : 'p-2.5'} bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex`}>
                            {sectionRef.current.profilePhoto ? (
                              <img
                                src={localData?.profilePhoto}
                                alt="profile photo"
                                className="w-20 h-20 relative flex-col justify-start items-start flex object-cover"
                              />
                            ) : (
                              <img src={cloud} alt="cloud" className="w-5 h-5 relative flex-col justify-start items-start flex" />
                            )}
                          </div>
                          <div className="self-stretch h-full flex-col justify-center items-center gap-1 flex">
                            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-gray-900 text-sm font-semibold leading-tight">
                                  Click to upload Profile Photo
                                </p>
                              </div>
                            </div>
                            <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                              SVG, PNG or JPG(max. 800x400px)
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>

                  </div>
                  <div className="self-stretch justify-start items-start gap-6 inline-flex">
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="firstName"
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            First Name<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="firstName"
                                type="text"
                                placeholder="'Alok'"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                value={localData?.name?.first}
                                onChange={handleData}
                                name="name.first"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="middleName"
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            Middle Name
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="middleName"
                                type="text"
                                placeholder="'Kumar'"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                value={localData?.name?.middle}
                                onChange={handleData}
                                name="name.middle"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="lastName"
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            Last Name
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="lastName"
                                type="text"
                                placeholder="'Mandal'"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                value={localData?.name?.last}
                                onChange={handleData}
                                name="name.last"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FieldCol1
                    fieldName="Profile Description"
                    fieldPlace="I have experience XXX …."
                    name="desc"
                    required
                    value={localData?.desc}
                    handleInput={handleData}
                  />

                  <div className=" self-stretch justify-start items-start gap-6 inline-flex">

                    <div className="justify-start items-start gap-8 flex">
                      <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">

                          <RefDropDown fieldName={"Email Address"} array={users} currentValue={professorData.userId} display={"email"} theValue={"_id"} handleInput={handleData} />
                        </div>
                      </div>
                    </div>


                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor={"linkedin"}
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            Linkedin Address
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                type="text"
                                required
                                id={"linkedin"}
                                name={"linkedin"}
                                value={localData.linkedin}
                                placeholder="linkedin.com/name"
                                onChange={handleData}
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="self-stretch flex-col justify-start items-center gap-1 flex">
                    <div className="self-stretch h-[118px] p-6 bg-purple-100 rounded-lg flex-col justify-start items-start gap-6 flex">
                      <div className="self-stretch justify-start items-center gap-6 inline-flex">
                        {localData?.mobile?.map((number, index) => (
                          <div className="h-[70px] justify-start items-start gap-8 flex" key={index}>
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                                <label
                                  htmlFor={`mobileNumber${index}`}
                                  className="text-zinc-700 text-base font-semibold leading-tight"
                                >
                                  Mobile Number {index + 1}
                                </label>
                                <div className="flex justify-center items-center gap-4">
                                  <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                      <input
                                        id={`mobileNumber${index}`}
                                        type="text"
                                        placeholder="1234512345"
                                        className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                        name="mobile"
                                        value={number}
                                        onChange={(e) => handleMobileNumbersChange(index, e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <img src={cross} alt="Remove mobile number" className="cursor-pointer w-4"
                                    onClick={() => removeNumber(index)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <button className="cursor-pointer text-violet-700 text-base font-semibold leading-tight px-3.5 py-2.5 rounded-lg shadow border border-gray-900 mt-6" onClick={addMobileNumber}>Add Mobile No.</button>
                      </div>
                    </div>
                  </div>

                  <FieldCol2
                    col1Name={"Address"}
                    col1Value={localData?.address}
                    col2Name={"Landmark"}
                    col2Value={localData?.landmark}
                    name1="address"
                    addressReq
                    landmarkReq
                    name2="landmark"
                    value1={localData?.address}
                    value2={localData?.landmark}
                    handleInput={handleData}
                  />


                  <div className="self-stretch justify-start items-start gap-6 inline-flex">
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="pincode"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            Pincode<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="pincode"
                                type="text"
                                placeholder="8253XX"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                value={localData?.pincode} onChange={handleData} name="pincode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="city"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            City<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="city"
                                type="text"
                                placeholder="Ranchi"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                value={localData?.city} onChange={handleData} name="city"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-start gap-8 flex">
                      <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="w-[187px] h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="district" className="text-zinc-700 text-base font-semibold leading-tight">
                            District<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="district"
                                type="text"
                                placeholder="district"
                                className="grow shrink pl-1 basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                value={localData.district} onChange={handleData} name="district"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="justify-start items-start gap-8 flex">
                      <div className="flex-col justify-start items-start gap-1.5 inline-flex min-w-[200px]">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          {/* <label htmlFor="state" className="text-zinc-700 text-base font-semibold leading-tight">
                            State<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch bg-white hover:bg-violet-50 rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div ref={dropdownRef} className="group w-full relative">
                              <button
                                type="button"
                                className="inline-flex px-3.5 py-2.5 text-gray-400 justify-between items-center w-full text-md font-medium focus:outline-none focus:bg-violet-200"
                                onClick={() => setShowStateOptions(!showStateOptions)}
                              >
                                <p>{localData.state || 'State'}</p>
                                <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
                              </button>
                              {showStateOptions && (
                                <div className="absolute left-0 z-10 text-center w-full mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg transition duration-300">
                                  <div className="py-1 max-h-40 overflow-x-auto">
                                    {stateArray && stateArray.map((state) => (
                                      <p
                                        key={state}
                                        className="m-2 cursor-pointer"
                                        onClick={() => handleStateChange(state)}
                                      >
                                        {state}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div> */}

                          <DropDown
                            fieldName={"State"}
                            array={stateArray}
                            handleInput={handleData}
                            name={"state"}
                            value={localData.state}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="self-stretch w-full gap-6 flex">
                    <div ref={dropdownRef} className="self-stretch w-[415.33px] flex-col justify-start items-start gap-1.5 flex">
                      <label htmlFor={"Languages"} className="w-[200px] text-zinc-700 text-base font-semibold leading-tight">
                        Languages
                      </label>
                      <div className="self-stretch bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                        <div className="w-full px-3.5 py-2.5 text-gray-700 focus:outline-none">
                          {inputValue}
                        </div>
                        <button
                          type="button"
                          className="px-3.5 py-2.5 hover:bg-violet-50"
                          onClick={() => setShowOptions(!showOptions)}>
                          <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
                        </button>
                      </div>
                      {showOptions && (
                        <div className="self-stretch z-10 mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg">
                          {languagesArray?.map((language, index) => (
                            <p key={index}
                              className="px-3.5 py-2.5 hover:bg-violet-50 cursor-pointer"
                              onClick={() => handleLanguageChange(index, language)}>
                              {language}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="flex-grow">
                      <div className="flex-grow">
                        <div className="h-[70px] flex-col gap-1.5 flex">
                          <label
                            htmlFor="selectedLanguages"
                            className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                          >
                            Selected Languages
                          </label>
                          <div className="flex-grow px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 gap-2 flex">
                            {localData.languages?.map((lang, i) => (
                              <div key={i} className="flex items-center">
                                <div className="px-3 bg-purple-100 rounded-[30px] justify-center items-center gap-1 flex">
                                  <div className="text-zinc-700 text-base font-normal flex-nowrap max-w-[120px] min-w-[20px] max-h-6 overflow-hidden">
                                    {lang}
                                  </div>
                                  <img src={cross} alt="Remove language" onClick={() => removeLang(i)} className="cursor-pointer" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BasicInfo;
