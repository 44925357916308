import React, { useEffect, useState } from "react";
import SideBar from "../Parts/SideBar2";
import { changePassword, getUserById, updateUserById } from "../../api/authAPI";
import Imagekit from "../Parts/Imagekit";
import { selectLoggedInUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const UserProfile = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    phone: "",
    profileUrl: "",
  });
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [success, setSuccess] = useState("");
  const mainUser = useSelector(selectLoggedInUser);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUserById();
        setUser(res);
      } catch (error) {
        console.error(error);
      }
    }

    fetchUser();
  }, [])

  const handleInput = (e) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value })
  }

  const passChange = async () => {
    try {
      // some checks on user inputs
      if (oldPass.trim() == "") {
        setErrMessage("Enter the old password.")
        setSuccess("");
      }
      // if nothing in new password
      else if (newPass.trim() == "") {
        setErrMessage("New password should contain characters.")
        setSuccess("")
      }
      else if (oldPass == newPass) {
        setErrMessage("Old and new passwords must be different.")
        setSuccess("")
      }
      else {
        const res = await changePassword({ oldPass: oldPass, newPass: newPass });
        setSuccess("Password changed.");
        setErrMessage("");
      }
    } catch (error) {
      setErrMessage(error.response?.data.error || "Failed to change password");
      setSuccess("");
    }
  }
  const onSave = async () => {
    // Save the user
    try {
      const res = await updateUserById(user);
      toast.success("User updated!");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="flex overflow-hidden bg-gray-100  ">
      <SideBar />
      <div className="ml-[15.5vw] w-[1500px] max-h-full mx-auto bg-white rounded-lg shadow-md p-6">
        <div className="relative">
          <div className="bg-gradient-to-r from-pink-300 to-orange-200 h-40 rounded-t-lg"></div>
          <label htmlFor="profileUrl" className="absolute -bottom-24 left-6 cursor-pointer">

            <Imagekit
              id={"profileUrl"}
              onSuccess={res => {
                setUser({ ...user, profileUrl: res.url });
              }}
            />

            {user?.profileUrl ? (
              <img src={user.profileUrl} alt="profile picture"
                className="h-[130px] w-[130px] rounded-full border-4 border-white object-cover bg-zinc-700" />) : (
              <img
                className="h-[130px] w-[130px] rounded-full border-4 border-white object-cover"
                src="https://via.placeholder.com/150"
                alt="Profile"
              />
            )
            }

            <span className="absolute bottom-2 right-2 block h-6 w-6 bg-blue-500 rounded-full border-2 border-white"></span>
          </label>
        </div>
        <div className="ml-[160px] mt-4 flex flex-col">
          <h2 className="text-2xl font-bold">{mainUser.user.name || "Name"}</h2>
          <p className="text-gray-600">
            Update your photo and personal details.
          </p>
        </div>
        <div className="mt-6 flex justify-end space-x-4 -translate-y-[60px] ml-[160px]">
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setUser({ ...user, profileUrl: "" })}
          >
            Unset Photo
          </button>
          <button
            type="button"
            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            onClick={onSave}
          >
            Update Profile
          </button>
        </div>
        <div className=" ml-10 mt-[80px] w-[1000px] flex flex-col justify-between space-y-6">
          <div className="flex items-center space-x-20">
            <label htmlFor="name" className="block w-[100px] text-sm font-medium text-gray-700">
              Username
            </label>
            <div className="flex flex-1 mt-1">
              <span className="inline-flex items-center px-3 rounded-l-md border-2 border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                gradbudy.com/
              </span>
              <input
                type="text"
                id="name"
                className="flex-1 w-full block px-3 py-2 rounded-r-md border-2 border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="username"
                value={user?.name || ""}
                onChange={handleInput}
              />
            </div>
          </div>

          <div className="flex items-center space-x-20">
            <label htmlFor="email" className="block w-[100px] text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 flex-1 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="www.gradbuddy.com"
              value={user?.email || ""}
              onChange={handleInput}
            />
          </div>

          <div className=" flex flex-col space-y-4">

            <div className="flex items-center space-x-20">
              <label htmlFor="oldPass" className="block w-[100px] text-sm font-medium text-gray-700">
                Old Password
              </label>
              <div className=" flex w-full space-x-3">
                <input
                  type="password"
                  id="oldPass"
                  className="flex-1 ml-4 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={oldPass}
                  onChange={e => setOldPass(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center space-x-20">
              <label htmlFor="newPass" className="block w-[100px] text-sm font-medium text-gray-700">
                New Password
              </label>
              <div className="flex flex-col w-full space-y-2">
                <div className=" flex w-full space-x-3">
                  <input
                    type="password"
                    id="newPass"
                    className="flex-1 ml-4 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={newPass}
                    onChange={e => setNewPass(e.target.value)}
                  />

                  <button
                    type="button"
                    onClick={passChange}
                    className=" px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                    Change
                  </button>
                </div>

                {/* messages */}
                <div className=" ml-4">
                  {errMessage && <p className=" text-red-500 text-sm font-medium">{errMessage}</p>}
                  {success && <p className=" text-green-500 text-sm font-medium">{success}</p>}
                </div>

              </div>
            </div>
          </div>

          <div className="flex items-center space-x-20">
            <label htmlFor="phone" className="block w-[100px] text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="number"
              id="phone"
              className="flex-1 mt-1 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="99887XXXXX"
              value={user?.phone || ""}
              onChange={handleInput}
            />
          </div>

          <div className="flex items-center space-x-20">
            <label htmlFor="role" className="block w-[100px] text-sm font-medium text-gray-700">
              Role
            </label>
            <input
              type="text"
              id="role"
              className="flex-1 mt-1 block px-3 py-2 border border-gray-300 text-zinc-700 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pointer-events-none"
              placeholder="Super Admin"
              value={user?.role || ""}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
