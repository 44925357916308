import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectError, setEmail, updateUserAsync } from '../../redux/authSlice';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalError = useSelector(selectError);
    const [isLoading, setIsLoading] = useState(false);
    const [localError, setLocalError] = useState('');
    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { email } = formData;

        // Basic validation
        if (!email.includes('@')) {
            setLocalError('Invalid email address');
            return;
        }

        setIsLoading(true);
        try {
            dispatch(setEmail(email));
            await dispatch(updateUserAsync(email)).unwrap();
            // On success, redirect to reset password page
            navigate('/reset-password');
        } catch (error) {
            console.error('Failed to reset password: ', error);
            setLocalError('Failed to reset password');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="absolute top-4 right-4">
                <span className='text-black'>No account ? </span>
                <Link to="/signup" className="text-blue-600 hover:underline">Sign up</Link>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
                <p className="text-gray-600 mb-6 text-center">Please enter the email you registered with</p>
                <form onSubmit={onSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex flex-col gap-4 pt-5">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        >
                            {isLoading ? (
                                <>
                                    <span className="loader"></span> &nbsp;
                                    Loading...
                                </>
                            ) : 'Forgot'}
                        </button>
                        {localError && <p className="mt-2 text-center text-red-600 text-sm">{localError}</p>}
                        {globalError && <p className="mt-2 text-center text-red-600 text-sm">{globalError}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
