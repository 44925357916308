import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkUser, createUser, resetPassword, updateUser } from "../api/authAPI";

const loadUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
};

const initialState = {
    loggedInUser: loadUserFromLocalStorage(),
    email: null,
    otp: null,
    status: "idle",
    error: null,
};

export const createUserAsync = createAsyncThunk(
    "auth/createUser",
    async (userData) => {
        const response = await createUser(userData);
        return response.data;
    }
);

export const updateUserAsync = createAsyncThunk(
    "auth/updateUser",
    async (email) => {
        const response = await updateUser(email);
        return response.data;
    }
);

export const checkUserAsync = createAsyncThunk(
    "auth/checkUser",
    async (loginInfo) => {
        const response = await checkUser(loginInfo);
        return response.data;
    }
);

export const resetPasswordAsync = createAsyncThunk(
    "auth/resetPassword",
    async (userData) => {
        const response = await resetPassword(userData);
        return response.data;
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setOtp: (state, action) => {
            state.otp = action.payload;
        },
        resetAuthState: (state) => {
            state.email = null;
            state.otp = null;
            state.loggedInUser = null;
            state.status = "idle";
            state.error = null;
        },
        signOut: (state) => {
            state.loggedInUser = null;
            state.email = null;
            state.otp = null;
            localStorage.removeItem("user");
        },
        setUserFromLocalStorage: (state) => {
            state.loggedInUser = loadUserFromLocalStorage();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUserAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createUserAsync.fulfilled, (state, action) => {
                state.status = "idle";
                const isFirstTimeSignup = !state.loggedInUser;
                state.loggedInUser = action.payload;
                if (isFirstTimeSignup) {
                    localStorage.setItem("user", JSON.stringify(action.payload));
                }
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.error.message || "Failed to create user";
            })
            .addCase(checkUserAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(checkUserAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.loggedInUser = action.payload;
                // console.log("User data stored in localStorage:", action.payload);
                localStorage.setItem("user", JSON.stringify(action.payload));
            })
            .addCase(checkUserAsync.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.error.message || "Failed to check user";
            })
            .addCase(updateUserAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateUserAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.loggedInUser = action.payload;
            })
            .addCase(updateUserAsync.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.error.message || "Failed to update user";
            })
            .addCase(resetPasswordAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(resetPasswordAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.loggedInUser = action.payload;
            })
            .addCase(resetPasswordAsync.rejected, (state, action) => {
                state.status = "idle";
                state.error = action.error.message || "Failed to reset password";
            });
    },
});

export const { setEmail, setOtp, resetAuthState, signOut, setUserFromLocalStorage } = authSlice.actions;

export const selectLoggedInUser = (state) => state.auth.loggedInUser;
export const selectError = (state) => state.auth.error;
export const selectEmail = (state) => state.auth.email;
export const selectOtp = (state) => state.auth.otp;

export default authSlice.reducer;
