import React, { useEffect, useState } from "react";

import SideBar from "../Parts/SideBar2";
import searchIcon from "../../assets/search-lg.svg";
import FieldCol1 from "../Parts/FieldCol1";
import cloud from "../../assets/upload-cloud-02.svg";
import SubTopicResource from "../Parts/SubTopicResource";
import Imagekit from "../Parts/Imagekit";
import { useNavigate, useParams } from "react-router-dom";
import { addDegree, editDegree, getDegreeById } from "../../api/degreeApi";
import { getSemesters } from "../../api/semesterApi";
import { toast } from 'react-toastify';


const AddDegree = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [userData, setUserData] = useState({
    thumbnail: "",
    degreeName: "",
    noOfSems: "",
    validity: "",
    credits: "",
    desc: "",
    semesters: []
  });
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");

  useEffect(() => {
    // get data
    const fetchDegreeData = async () => {
      try {
        const res = await getDegreeById(id);

        setUserData(res.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (id && !userData.degreeName) fetchDegreeData();
  }, [id, userData]);

  useEffect(() => {
    const fetchSems = async () => {
      try {
        const res = await getSemesters();
        setFilteredData(res
          .filter(sem => !userData.semesters.some(s => s._id === sem._id))
          .map(sem => ({ _id: sem._id, semName: sem.semName, available: true }))
        )
      } catch (error) {
        console.error(error);
      }
    }

    if (filteredData.length === 0) {
      fetchSems();
    }
  }, [userData.semesters]);

  useEffect(() => {
    let filtered = filteredData;
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.semName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (filterTerm) {
      setFilterTerm(filtered.filter(item =>
        item.category.toLowerCase().includes(filterTerm.toLowerCase())
      ));
    }
    setFilteredData(filtered.slice(0, 10));
  }, [searchTerm, filterTerm]);

  const handleData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleListSubmit = () => {
    setUserData(prev => {
      const newSems = filteredData.filter(s => !s.available);
      return { ...prev, semesters: [...prev.semesters, ...newSems] }
    });

    setFilteredData(prev => prev.filter(s => s.available));
  }

  const handleDegreeSubmit = async () => {
    if (!userData.thumbnail) {
      toast.error("Please upload the Thumbnail.");
      return;
    }

    if (!userData.degreeName) {
      toast.error("Please fill the required field: Degree Name.");
      return;
    }

    if (!userData.noOfSems) {
      toast.error("Please fill the required field: Number of Semesters.");
      return;
    }

    if (!userData.validity) {
      toast.error("Please fill the required field: Validity.");
      return;
    }

    if (!userData.credits) {
      toast.error("Please fill the required field: Credits.");
      return;
    }

    if (!userData.desc) {
      toast.error("Please fill the required field: Description.");
      return;
    }

    // Semesters validation
    if (!userData.semesters || userData.semesters.length === 0) {
      toast.error("Please add at least one Semester.");
      return;
    }


    try {
      const semIds = userData.semesters.map(s => s._id);
      const finalData = {
        ...userData,
        semesters: semIds
      }
      const res = id
        ? await editDegree(id, finalData)
        : await addDegree(finalData);

      if (res) {
        toast.success(id ? "Degree updated successfully." : "Degree added successfully.");
        nav('/dashboard/degree');
      } else {
        toast.error("Error, please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the data.");
    }
  }

  return (
    <div className="bg-purple-50 justify-start items-start inline-flex pl-[15.5vw] w-full">
      <SideBar />

      <div className="grow shrink basis-0 self-stretch justify-start items-start inline-flex">
        <div className="grow shrink basis-0 h-[1134px] pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 h-[1022px] px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
              <div className="self-stretch h-[1022px] flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold   leading-tight">
                          Add Degree
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <label htmlFor="thumbnail" className="w-[512px] cursor-pointer flex-col justify-start items-start gap-4 inline-flex">

                      <div className="self-stretch h-[126px] px-6 py-4 bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                        <div className="self-stretch h-full justify-center items-center gap-3 flex">
                          <Imagekit
                            id={"thumbnail"}
                            onSuccess={res => {
                              const updated = { ...userData, thumbnail: res.url }
                              setUserData(updated);
                            }}
                          />
                          <div className=" flex-col justify-start items-start gap-1 flex">
                            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-gray-900 text-sm font-semibold leading-tight">
                                  Click to upload Thumbnail image
                                </p>
                              </div>
                            </div>
                            <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                              SVG, PNG or JPG(max. 800x400px)
                            </p>
                          </div>
                          {userData.thumbnail ? (
                            <img
                              src={userData.thumbnail}
                              alt="profile photo"
                              className="w-[100px] h-[100px]"
                            />
                          ) : (
                            <div className="w-15 h-15 p-2.5 bg-gray-200 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                              <img
                                src={cloud}
                                alt="cloud"
                                className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <div className="self-stretch h-[70px] w-[635px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="degreeName"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            Degree Name<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="degreeName"
                                type="text"
                                placeholder="Prabandhnagar"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                value={userData.degreeName}
                                onChange={e => handleData(e)}
                                name="degreeName"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start gap-6 inline-flex">
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] w-[600px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="noOfSem"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            Number of Semesters<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="noOfSem"
                                type="number"
                                placeholder="12"
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                value={userData.noOfSems}
                                onChange={e => handleData(e)}
                                name="noOfSems"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] w-[236px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="validity"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            Validity<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="validity"
                                type="text"
                                placeholder=""
                                className="grow shrink basis-0 text-zinc-700 text-base font-normal w-[130px]  leading-normal focus:outline-none"
                                value={userData.validity}
                                onChange={e => handleData(e)}
                                name="validity"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grow shrink basis-0 h-[70px] justify-start  translate-x-[-45px] items-start gap-8 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] w-[153px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="credits"
                            className="text-zinc-700 text-base font-semibold   leading-tight"
                          >
                            Credits<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 w-[153px] bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="credits"
                                type="text"
                                placeholder=""
                                className="grow shrink basis-0 text-zinc-700 text-base w-[130px] font-normal   leading-normal focus:outline-none"
                                value={userData.credits}
                                onChange={e => handleData(e)}
                                name="credits"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FieldCol1
                    fieldName={"Description"}
                    fieldPlace={"This contains XXX …."}
                    name={"desc"}
                    required
                    value={userData.desc}
                    handleInput={handleData}
                  />

                  <div className="w-full">
                    <h2 className="text-xl font-bold mb-4 ">List of Semesters<span className="text-red-500">*</span></h2>
                    <div className=" w-full h-[216px] p-6 bg-purple-100 rounded-lg justify-start items-start gap-4 flex flex-row overflow-x-auto overflow-y-hidden">
                      {userData.semesters?.map((res, i) => (
                        <div key={i} className="flex-shrink-0">
                          <SubTopicResource
                            headingName={res?.semName}
                            // ranking={res.ranking}
                            handleRanking={e => { res.ranking = e }}
                            handleRemove={() => {
                              setUserData(prev => ({
                                ...prev,
                                semesters: prev.semesters.filter(s => s._id !== res._id)
                              }))
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="w-[926px] h-[486px] relative bg-white">
                    <div>
                      <div className="left-[199px] top-[24px] absolute justify-start items-center gap-3 inline-flex">
                        <div className="w-[400px] flex-col justify-start items-start gap-2 inline-flex">
                          <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <img src={searchIcon} alt="search" className="w-5 h-5 relative" />
                                <input
                                  type="text"
                                  className="w-full h-full text-gray-500 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                  placeholder="Search"
                                  value={searchTerm || ''}
                                  onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="w-[730px] left-[137px] top-[90px] absolute justify-start items-start gap-4 grid grid-cols-7">
                        {filteredData?.map((item, index) => (
                          <div
                            key={index}
                            className={`flex-col justify-start items-center gap-2.5 inline-flex cursor-pointer`}
                            onClick={() => {
                              setSelectedId(item._id)
                              // change availability if file is selected.
                              const updated = filteredData.map(file =>
                                file._id === item._id ? { ...file, available: !file.available } : file
                              );
                              setFilteredData(updated);
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <div className={`w-24 h-[72px] bg-zinc-300 rounded-lg ${!item.available ? 'border-2 border-blue-500' : 'border-2 border-transparent'}`}></div>
                            <p className="text-black text-sm font-semibold font-['Source Sans Pro'] truncate w-24" title={item.topicName}>
                              {item.semName}
                            </p>
                          </div>
                        ))}
                      </div>
                      <button
                        className="w-[730px] px-4 py-2.5 left-[123px] bottom-16 absolute bg-amber-500 rounded-lg shadow justify-center items-center gap-2 inline-flex"
                        onClick={handleListSubmit}
                      >
                        <p className="text-white text-sm font-semibold font-['Inter'] leading-tight">
                          Add to List
                        </p>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex max-w-[75vw]">
                  <button
                    onClick={handleDegreeSubmit}
                    type="submit"
                    className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
                  >
                    <p className="text-white text-base font-semibold   leading-normal">
                      Submit
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDegree;
