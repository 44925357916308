import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import SideBar from "../Parts/SideBar2";
import ProgressSteps4 from "./ProgressSteps4_students";
import { useDispatch, useSelector } from "react-redux";
import { selectStudent, updateEducationBackground } from "../../redux/studentsSlice";
import DropDown from "../Parts/DropDown";
import { getAllEntries } from "../../api/configurationAPI";
import { toast } from 'react-toastify';


const EducationBackground = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const educationBackground = useSelector((state) => state.students.education);
  const studentData = useSelector(selectStudent) || null

  const [localData, setLocalData] = useState(educationBackground);
  const sectionRef = useRef(educationBackground);

  const [educationBoardsArray, setEducationBoardsArray] = useState([]);

  const fetchEducationBoardsArray = async () => {
    try {
      const educationBoardsData = await getAllEntries('educationBoards');
      if (educationBoardsData.length > 0) {
        setEducationBoardsArray(educationBoardsData.map(board => board.name));
      }
    } catch (error) {
      console.error('Failed to fetch education boards:', error);
    }
  };

  useEffect(() => {
    fetchEducationBoardsArray();
  }, []);


  useEffect(() => {
    if (studentData.education) {
      setLocalData(studentData.education);
    } else {
      sectionRef.current = educationBackground;
      setLocalData(educationBackground);
    }
  }, [educationBackground, studentData.education]);

  useEffect(() => {
    dispatch(updateEducationBackground(sectionRef.current));
  }, []);

  const handleData = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    if (keys.length > 1) {
      // Handling nested properties
      const [key, subKey] = keys;
      setLocalData(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [subKey]: value
        }
      }));
    } else {
      // Handling flat properties
      setLocalData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = () => {
    const secondary = localData?.secondary;
    const seniorSec = localData?.seniorSec;
    const diploma = localData?.diploma;
    const college = localData?.college;

    if (!secondary?.name || !secondary?.board || !secondary?.stream || !secondary?.passingYear) {
      toast.error("Secondary education details are incomplete.");
      return false;
    }

    if (!seniorSec?.name || !seniorSec?.board || !seniorSec?.stream || !seniorSec?.marks || !seniorSec?.passingYear) {
      toast.error("Senior Secondary education details are incomplete.");
      return false;
    }

    if (seniorSec.marks < 0 || seniorSec.marks > 100) {
      toast.error("Senior Secondary marks must be between 0 and 100.");
      return false;
    }

    if (!diploma?.name || !diploma?.stream || !diploma?.passingYear) {
      toast.error("Diploma education details are incomplete.");
      return false;
    }

    if (!college?.name || !college?.stream || !college?.branch || !college?.startingYear || !college?.passingYear) {
      toast.error("College education details are incomplete.");
      return false;
    }

    // If all validations pass
    sectionRef.current = localData;
    dispatch(updateEducationBackground(localData));

    if (props.onSubmit) props.onSubmit();

    return true; // Return true for validation success
  };


  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="justify-start items-start inline-flex">
      <SideBar />

      <div className="basis-0 ml-[15.5vw] self-stretch justify-start items-start flex w-[1300px]">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">

              <div className=" flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="2" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Education Background
                        </p>
                      </div>
                    </div>
                  </div>


                </div>



                {/* Sec. below*/}


                <div className="self-stretch justify-start items-start gap-6 inline-flex">
                  <div className="basis-0 h-[220px] bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                        <label
                          htmlFor="secondarySchoolName"
                          className="text-zinc-700 text-base font-semibold   leading-tight"
                        >
                          Secondary School Name<span className="text-red-500">*</span>
                        </label>
                        <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                          <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                            <input
                              id="secondarySchoolName"
                              type="text"
                              placeholder="“School Name”"
                              className="w-[75vw] grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                              value={localData.secondary.name} onChange={handleData} name="secondary.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <div className='bg-red'>hiii</div> */}
                    <div className="self-stretch w-[100%] justify-start items-start gap-6 inline-flex">

                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex mt-2">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <DropDown
                              fieldName={"Board"}
                              array={educationBoardsArray}
                              handleInput={handleData}
                              name={"secondary.board"}
                              value={localData.secondary.board}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="secondaryStream"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Stream<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="secondaryStream"
                                  type="text"
                                  placeholder="Diploma Stream"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.secondary.stream} onChange={handleData} name="secondary.stream"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                      <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                        <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="date" className="text-zinc-700 text-base font-semibold   leading-tight">
                            Passing Year<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                            <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                              <input
                                type="date"
                                id="date"
                                name="secondary.passingYear"
                                value={localData.secondary.passingYear?.substr(0, 10) || ""}
                                onChange={handleData}
                                className="focus:outline-none text-zinc-700"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>





                {/* Senior Sec. below*/}


                <div className="self-stretch justify-start items-start gap-6 inline-flex">
                  <div className="basis-0 h-[220px] bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                        <label
                          htmlFor="seniorSecondarySchoolName"
                          className="text-zinc-700 text-base font-semibold   leading-tight"
                        >
                          Senior Secondary School Name<span className="text-red-500">*</span>
                        </label>
                        <div className=" self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                          <div className="  grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                            <input
                              id="seniorSecondarySchoolName"
                              type="text"
                              placeholder="“School Name”"
                              className="w-[75vw] grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                              value={localData.seniorSec.name} onChange={handleData} name="seniorSec.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <div className='bg-red'>hiii</div> */}
                    <div className="self-stretch w-[100%] justify-start items-start gap-6 inline-flex">

                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex mt-2">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <DropDown
                              fieldName={"Board"}
                              array={educationBoardsArray}
                              handleInput={handleData}
                              name={"seniorSec.board"}
                              value={localData.seniorSec.board}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="seniorSecondaryStream"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Stream<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="seniorSecondaryStream"
                                  type="text"
                                  placeholder="Diploma Stream"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.seniorSec.stream} onChange={handleData} name="seniorSec.stream"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="seniorSecondaryMarks"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Marks Scored<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="seniorSecondaryMarks"
                                  type="text"
                                  placeholder="senior Secondary Marks"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.seniorSec.marks} onChange={handleData} name="seniorSec.marks"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                        <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="seniorSecondaryPassingYear" className="text-zinc-700 text-base font-semibold   leading-tight">
                            Passing Year<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                            <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                              {/* <img
                                src={calendar}
                                alt="calendar"
                                className="w-5 h-5 relative"
                              />
                              <p className="text-gray-500 text-sm font-semibold   leading-tight">
                                Select date
                              </p> */}
                              <input
                                type="date"
                                id="seniorSecondaryPassingYear"
                                name="seniorSec.passingYear"
                                value={localData.seniorSec.passingYear?.substr(0, 10) || ""}
                                onChange={handleData}
                                className="focus:outline-none text-zinc-700"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>






                {/* Diploma below*/}


                <div className="self-stretch justify-start items-start gap-6 inline-flex">
                  <div className="basis-0 h-[220px] bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                        <label
                          htmlFor="diplomaSchoolName"
                          className="text-zinc-700 text-base font-semibold   leading-tight"
                        >
                          Diploma<span className="text-red-500">*</span>
                        </label>
                        <div className=" self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                          <div className="  grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                            <input
                              id="diplomaSchoolName"
                              type="text"
                              placeholder="“School Name”"
                              className="w-[75vw] grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                              value={localData.diploma.name} onChange={handleData} name="diploma.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <div className='bg-red'>hiii</div> */}
                    <div className="self-stretch w-[70%] justify-start items-start gap-6 inline-flex">


                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="diplomaStream"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Stream<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="diplomaStream"
                                  type="text"
                                  placeholder="Diploma Stream"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.diploma.stream} onChange={handleData} name="diploma.stream"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                      <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                        <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="diplomaPassingYear" className="text-zinc-700 text-base font-semibold   leading-tight">
                            Passing Year<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                            <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                              <input
                                type="date"
                                id="diplomaPassingYear"
                                name="diploma.passingYear"
                                value={localData.diploma.passingYear?.substr(0, 10) || ""}
                                onChange={handleData}
                                className="focus:outline-none text-zinc-700"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>


                {/* College below*/}


                <div className="self-stretch justify-start items-start gap-6 inline-flex">
                  <div className="basis-0 h-[220px] bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                        <label
                          htmlFor="collegeName"
                          className="text-zinc-700 text-base font-semibold   leading-tight"
                        >
                          College Name<span className="text-red-500">*</span>
                        </label>
                        <div className=" self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                          <div className="  grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                            <input
                              id="collegeName"
                              type="text"
                              placeholder="“School Name”"
                              className="w-[75vw] grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                              value={localData.college.name} onChange={handleData} name="college.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <div className='bg-red'>hiii</div> */}
                    <div className="self-stretch w-[100%] justify-start items-start gap-6 inline-flex">

                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="collegeStream"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Stream<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="collegeStream"
                                  type="text"
                                  placeholder="college Stream"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.college.stream} onChange={handleData} name="college.stream"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="collegeBranch"
                              className="text-zinc-700 text-base font-semibold   leading-tight"
                            >
                              Branch<span className="text-red-500">*</span>
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="collegeBranch"
                                  type="text"
                                  placeholder="college Branch"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal   leading-normal focus:outline-none"
                                  value={localData.college.branch} onChange={handleData} name="college.branch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                        <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="college.startingYear" className="text-zinc-700 text-base font-semibold   leading-tight">
                            Starting Year<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                            <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                              <input
                                type="date"
                                id="college.startingYear"
                                name="college.startingYear"
                                value={localData.college.startingYear?.substr(0, 10) || ""}
                                onChange={handleData}
                                className="focus:outline-none text-zinc-700"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                        <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                          <label htmlFor="collegePassingYear" className="text-zinc-700 text-base font-semibold   leading-tight">
                            Passing Year<span className="text-red-500">*</span>
                          </label>
                          <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                            <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                              <input
                                type="date"
                                id="collegePassingYear"
                                name="college.passingYear"
                                value={localData.college.passingYear?.substr(0, 10) || ""}
                                onChange={handleData}
                                className="focus:outline-none text-zinc-700"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EducationBackground;
