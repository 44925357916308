import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProfessorExperience, resetProfessor, selectProfessor } from "../../redux/professorSlice";
import SideBar from "../Parts/SideBar2";
import ProgressSteps4 from "./progressBar";
import FieldCol2 from "../Parts/FieldCol2";
import FieldCol1 from "../Parts/FieldCol1";
import { addProfessor, editProfessor } from "../../api/professorApi";
import { toast } from 'react-toastify';

const Experience = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const professorData = useSelector(selectProfessor) || null
  const experiences = useSelector((state) => professorData.experience);
  const [localData, setLocalData] = useState(experiences);
  const sectionRef = useRef(experiences);

  useEffect(() => {
    setLocalData(professorData.experience || []);
    sectionRef.current = professorData.experience || [];
  }, [professorData.experience]);

  useEffect(() => {
    dispatch(updateProfessorExperience(sectionRef.current));
  }, []);

  const handleExperienceChange = (index, key, value) => {
    const updatedExperiences = [...localData];
    updatedExperiences[index] = { ...updatedExperiences[index], [key]: value };
    setLocalData(updatedExperiences);
  };

  const addNewExperience = () => {
    const newExperience = { designation: '', org: '', startDate: '', endDate: '', desc: '' };
    setLocalData([...localData, newExperience]);
  };

  const removeSpec = (ind) => {
    const newSpecs = localData.filter((_, i) => i !== ind)
    setLocalData(newSpecs)
  }

  const validateData = () => {
    for (const experience of localData) {
      if (!experience.designation || !experience.org || !experience.startDate) {
        return false;
      }
    }
    return true;
  };

  const handlePrev = () => {
    sectionRef.current = localData;
    dispatch(updateProfessorExperience(localData));
  }

  const handleSubmit = async () => {

    if (!validateData()) {
      toast.error("Please fill all required fields.");
      return;
    }

    sectionRef.current = localData;
    dispatch(updateProfessorExperience(localData));

    const updatedProfessor = {
      ...professorData,
      experience: localData,
    };

    try {
      let res;
      if (id) {
        res = await editProfessor(id, updatedProfessor);
        if (res === undefined) {
          toast.error("Error occurred while updating professor.");
        } else {
          toast.success("Professor updated successfully");
        }
      } else {
        res = await addProfessor(updatedProfessor);
        if (res === undefined) {
          toast.error("Error occurred while adding professor.");
        } else {
          toast.success("Professor added successfully");
        }
      }
      if (res !== undefined) {
        dispatch(resetProfessor());
        navigate("/dashboard/professor");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while submitting the data.");
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
    handlePrev,
  }));

  return (
    <div className="bg-purple-50 justify-start items-start inline-flex">
      <SideBar />

      <div className="grow ml-[15.5vw] shrink basis-0 self-stretch bg-purple-50 justify-start items-start inline-flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="4" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Experience
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-purple-100 self-stretch flex-col justify-start items-start gap-6 flex py-3.5 px-3.5">
                    {localData.map((exp, index) => (
                      <React.Fragment key={index}>
                        <FieldCol2
                          col1Name={"Experience Designation"}
                          col1Value={"Experience Designation"}
                          value1={exp.designation}
                          col2Name={"Organization"}
                          col2Value={"Organization"}
                          addressReq
                          landmarkReq
                          value2={exp.org}
                          name1={`designation`}
                          name2={`org`}
                          handleInput={(e) => handleExperienceChange(index, e.target.name, e.target.value)}
                        />

                        <div className="flex">
                          <div className="w-52 h-[70px] justify-end items-start gap-8 flex mr-8">
                            <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-zinc-700 text-base font-semibold leading-tight">
                                Start Date<span className="text-red-500">*</span>
                              </div>
                              <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                                <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                                  <input
                                    type="date"
                                    name="startDate"
                                    value={formatDate(exp.startDate)}
                                    onChange={(e) => handleExperienceChange(index, e.target.name, e.target.value)}
                                    className="text-gray-500 text-sm font-semibold leading-tight focus:outline-none"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                            <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                              <div className="text-zinc-700 text-base font-semibold leading-tight">
                                End Date<span className="text-red-500">*</span>
                              </div>
                              <div className={`self-stretch h-11 flex-col justify-start items-end gap-2 flex ${exp.currentlyWorking && ' opacity-50 cursor-not-allowed'}`}>
                                <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                                  <input
                                    type="date"
                                    name="endDate"
                                    value={formatDate(exp.endDate)}
                                    onChange={(e) => handleExperienceChange(index, e.target.name, e.target.value)}
                                    className={`text-gray-500 text-sm font-semibold leading-tight focus:outline-none ${exp.currentlyWorking && ' opacity-50 cursor-not-allowed'}`}
                                    disabled={exp.currentlyWorking}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center mt-2">
                                <input
                                  type="checkbox"
                                  id={`currentlyWorking-${index}`}
                                  checked={exp.currentlyWorking}
                                  onChange={(e) => handleExperienceChange(index, 'currentlyWorking', e.target.checked)}
                                  className="mr-2"
                                />
                                <label htmlFor={`currentlyWorking-${index}`} className="text-gray-700 text-sm font-semibold leading-tight">
                                  Currently working here
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>


                        <FieldCol1
                          fieldName={"Experience Description"}
                          fieldPlace={"I have Experience XXX …."}
                          name="desc"
                          required
                          value={exp.desc}
                          handleInput={(e) => handleExperienceChange(index, e.target.name, e.target.value)}
                        />
                        <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={() => removeSpec(index)}>Remove</p>
                      </React.Fragment>
                    ))}
                  </div>

                  <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={addNewExperience}>
                    + Add Experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};


export default Experience;
