import React, { useEffect, useState } from "react";
import SideBar from "../Parts/SideBar2";

import FieldCol1 from "../Parts/FieldCol1";
import { useNavigate, useParams } from "react-router-dom";
import { createVideo, getVideoById, updateVideo } from "../../api/videoApi";
import { getProfessors } from "../../api/professorApi";
import RefDropDown from "../Parts/RefDropDown";
import { Loader2 } from 'lucide-react';
import { toast } from 'react-toastify';
import Imagekit from "../Parts/Imagekit";

const Video = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [videoData, setVideoData] = useState({
    youtubeLink: "",
    videoName: "",
    videoUrl: "",
    creator: "",
    lengthOfVideoInMinutes: "",
    description: "",
  });
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState("");
  const [professors, setProfessors] = useState([]);
  // To select 1: file / yt link: none | yt | file
  const [videoSource, setVideoSource] = useState("none");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchProfessors();
    if (id) fetchVideoData();
  }, [id]);

  const fetchProfessors = async () => {
    try {
      const res = await getProfessors();
      const data = res.map(p => p = { _id: p._id, creator: `${p.info.name.first} ${p.info.name.last}` });
      setProfessors(data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchVideoData = async () => {
    try {
      const res = await getVideoById(id);
      setVideoData(res);
      setUploadedFile(file?.name);
      // if vimeo, unset youtube link
      if (videoData.videoUrl?.includes("vimeo")) setVideoData({ ...videoData, youtubeLink: "" })
    } catch (err) {
      console.error(err);
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    setVideoData({ ...videoData, [id]: value });
  };

  const validateFileSize = (fileSize) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 1 MB
    if (fileSize > MAX_FILE_SIZE) {
      toast.error("File size should not exceed 100 KB");
      return false;
    }
    return true;
  };

  const handleFileUpload = (res) => {
    if (validateFileSize(res.size)) {
      setUploadedFile(res.name); // Optionally set the uploaded file name
      setVideoSource("file");
      setVideoData({ ...videoData, videoUrl: res.url, youtubeLink: "" });
      setFile(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if neither is provided
    if (!videoData.youtubeLink && !videoData.videoUrl) {
      toast.error("Please select a video source: either upload a file or provide a YouTube link.");
      return;
    }
    setIsLoading(true);

    try {
      const res = id
        ? await updateVideo(id, videoData)
        : await createVideo(videoData);
      // console.log(res);

      setIsLoading(false);

      setVideoData({
        youtubeLink: "",
        videoName: "",
        creator: "",
        lengthOfVideoInMinutes: "",
        description: "",
        videoUrl: '',
      });
      setUploadedFile("");

      nav("/dashboard/document")
    } catch (err) {
      toast.error("Server Error.")
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="bg-purple-50">
      <div className="justify-start items-start inline-flex">
        <SideBar />
        <form
          onSubmit={handleSubmit}
          className="grow shrink basis-0 ml-[15.5vw] self-stretch bg-purple-50 justify-start items-start flex"
        >
          <div className="grow shrink basis-0 h-[776px] pt-4 pb-24 justify-start items-center gap-16 flex">
            <div className="grow shrink basis-0 h-[664px] px-8 justify-center items-start flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                <div className="self-stretch h-[664px] flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch h-[664px] flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch justify-start items-start gap-8 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                          <div className="text-zinc-700 text-4xl font-bold font-['Inter'] leading-tight">
                            Upload Video
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor="videoFile"
                      className={`w-[512px] flex-col justify-start items-start cursor-pointer gap-4 inline-flex
    ${videoSource === "yt" ? ` opacity-50 pointer-events-none` : ``}`}
                    >
                      <div className="self-stretch h-[126px] bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                        <Imagekit
                          id="videoFile"
                          accept=".mp4,.avi,.mov,.html5"
                          onSuccess={(res) => {
                            console.log("res: ", res);
                            handleFileUpload(res);
                          }}
                        />
                        <div className="self-stretch h-full justify-center items-center gap-10 flex">
                          <div className="flex-col justify-start items-start gap-1 flex">
                            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-gray-900 text-sm font-semibold leading-tight">
                                  {videoData.videoUrl?.includes("vimeo") ? (
                                    <a
                                      href={videoData.videoUrl}
                                      className="text-violet-800 px-2 py-2 bg-violet-100 rounded-lg"
                                    >
                                      {videoData.videoUrl}
                                    </a>
                                  ) : (
                                    uploadedFile || "Upload Video"
                                  )}
                                </p>
                              </div>
                            </div>
                            <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                              .mp4 .avi .mov .html5 accepted (maximum 7MB)
                            </p>
                          </div>
                        </div>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setVideoData({ ...videoData, videoUrl: "", youtubeLink: "" });
                            setFile(null);
                            setUploadedFile("");
                            setVideoSource("none");
                          }}
                          className="text-sm text-red-400 py-1 px-2 font-bold"
                        >
                          Remove
                        </button>
                      </div>
                    </label>

                    <div className="w-[635px] justify-start items-start gap-8 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor="youtubeLink"
                            className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                          >
                            Attach YouTube Link
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id="youtubeLink"
                                type="text"
                                value={videoData.videoUrl?.includes("vimeo") ? "" : videoData.youtubeLink}
                                onChange={e => {
                                  // set yt, reset local file
                                  if (e.target.value.trim() == "") {
                                    setVideoSource("none");
                                    return;
                                  }
                                  setVideoSource("yt");
                                  setVideoData({ ...videoData, youtubeLink: e.target.value, videoUrl: e.target.value });
                                  setFile(null);
                                  setUploadedFile("");
                                }
                                }
                                placeholder="Link"
                                className={`grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none ${videoSource === "file" ? "pointer-events-none" : ""}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-start gap-6 inline-flex">
                      <div className=" justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="videoName"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              Video Name
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="videoName"
                                  type="text"
                                  placeholder="Video Name"
                                  value={videoData.videoName}
                                  onChange={handleInput}
                                  required
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="justify-start items-start gap-8 flex">
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">

                            <RefDropDown fieldName={"Creator"} array={professors} currentValue={videoData.creator} display={"creator"} theValue={"_id"} handleInput={handleInput} />

                          </div>
                        </div>
                      </div>
                      <div className="justify-start items-start gap-8 flex">
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="lengthOfVideoInMinutes"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              Length of Video in minutes
                            </label>
                            <div className="w-56 h-11 flex-col justify-start items-start flex">
                              <input
                                id="lengthOfVideoInMinutes"
                                type="number"
                                value={videoData.lengthOfVideoInMinutes}
                                onChange={handleInput}
                                required
                                min={1}
                                placeholder="20"
                                className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
                      <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
                        <FieldCol1
                          fieldName={"Video Description"}
                          fieldPlace={"This Video contains XXX..."}
                          name={"description"}
                          value={videoData.description}
                          handleInput={handleInput}
                        />
                      </div>
                    </div>
                    <div className=" self-stretch flex-col justify-start items-start gap-4 flex">
                      <button
                        type="submit"
                        className={`w-full max-w-[77vw] px-5 py-3 ${isLoading ? "bg-amber-400" : "bg-amber-500"} rounded-lg shadow border justify-center items-center gap-2 inline-flex`}
                        disabled={isLoading}
                      >
                        <div className="text-white text-base font-semibold font-['Inter'] leading-normal flex">
                          {isLoading ? (
                            <>
                              <Loader2 size={20} className="animate-spin" /> &nbsp; Uploading...
                            </>
                          ) : 'Submit'}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Video;
