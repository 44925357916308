import API from './config';

// topic last section

export const addTopic = async(topic) => {
  try {
    const res = await API.post('api/topics/', topic);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editTopic = async(id, topic) => {
  try {
    const res = await API.put(`api/topics/${id}`, topic);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// topic dashboard

export const getTopicById = async(id) => {
  try {
    const res = await API.get(`api/topics/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getTopics = async() => {
 
  try {
    const res = await API.get('api/topics/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`api/topics/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delTopic = async(id) => {
  try {
    await API.delete(`api/topics/${id}`);
  } catch (error) {
    console.error(error);
  }
};
