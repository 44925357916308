import React, { useEffect, useState } from 'react'
import { addPrice, editPrice } from '../../api/priceApi';
import { editCourse, getCourseById } from '../../api/courseApi';
import { useNavigate } from 'react-router-dom';

const Popup = ({ isVisible, onClose, courseId }) => {
  const nav = useNavigate();
  const [details, setDetails] = useState({
    price: "", discount: "", gst: "", hsnCode: "", courseId: ""
  });

  useEffect(() => {
    if (courseId) {
      const getCourse = async () => {
        try {
          const response = await getCourseById(courseId);
          if (response.data.priceId) {
            setDetails(prevDetails => ({
              ...prevDetails,
              ...response.data.priceId,
              courseId: courseId
            }));
          } else {
            // If there's no priceId, just set the courseId
            setDetails(prevDetails => ({
              ...prevDetails,
              courseId: courseId
            }));
          }
          // console.log("Fetched course data:", response.data);
        } catch (error) {
          console.error(error);
        }
      }
      getCourse();
    }
  }, [courseId]);

  // console.log("details", details);

  if (!isVisible) return null;

  const handleInput = e => {
    const { id, value } = e.target;
    setDetails(prev => ({
      ...prev, [id]: value
    }));
  };
  // console.log("course id", courseId);

  const handleSubmit = async () => {
    // make model. add this id in the course.
    try {
      let res;
      if (details._id) {
        // edit
        res = await editPrice(details._id, details);
      } else {
        // new price
        res = await addPrice(details);
        await editCourse(courseId, { priceId: res._id });
      }

      console.log("final", res);

      nav('/dashboard/course');

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="fixed z-20 overflow-auto inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center">
      <div className="px-24 pt-20 pb-16 bg-white shadow-md relative">
        <button onClick={onClose} className="absolute top-3 right-5 rotate-45">
          <span className="text-black/60 hover:text-black/80 duration-150 hover:scale-105 text-3xl">✚</span>
        </button>

        <div className="flex flex-col gap-5">
          <div className="self-stretch h-[70px] w-[20vw] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor="price"
              className="text-zinc-700 text-base font-semibold   leading-tight"
            >
              Price
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  id="price"
                  type="number"
                  placeholder="₹ 5999"
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal  leading-normal focus:outline-none"
                  value={details?.price || ""}
                  onChange={handleInput}
                  name="price"
                />
              </div>
            </div>
          </div>

          <div className="self-stretch h-[70px] w-[20vw] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor="discount"
              className="text-zinc-700 text-base font-semibold   leading-tight"
            >
              Discount
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  id="discount"
                  type="text"
                  placeholder="₹ 499 || 20 %"
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal  leading-normal focus:outline-none"
                  value={details?.discount || ""}
                  onChange={handleInput}
                  name="discount"
                />
              </div>
            </div>
          </div>

          <div className="self-stretch h-[70px] w-[20vw] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor="numberOfPDFs"
              className="text-zinc-700 text-base font-semibold   leading-tight"
            >
              GST
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  id="gst"
                  type="number"
                  placeholder="Enter gst"
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal  leading-normal focus:outline-none"
                  value={details?.gst || ""}
                  onChange={handleInput}
                  name="gst"
                />
              </div>
            </div>
          </div>

          <div className="self-stretch h-[70px] w-[20vw] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor="hsnCode"
              className="text-zinc-700 text-base font-semibold   leading-tight"
            >
              HSN Code
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  id="hsnCode"
                  type="text"
                  placeholder="HSN Code"
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal  leading-normal focus:outline-none"
                  value={details?.hsnCode}
                  onChange={handleInput}
                  name="hsnCode"
                />
              </div>
            </div>
          </div>

          <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex pt-6 px-6">
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
                onClose();
              }}
              className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
            >
              <p className="text-white text-lg font-semibold leading-normal">
                Submit Course
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
