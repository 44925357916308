import { configureStore } from '@reduxjs/toolkit';
import collegeReducer from './collegeReducer';
import studentsReducer from './studentsSlice';
import professorReducer from './professorSlice';
import universityReducer from './universityReducer';
import authReducer from './authSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    college: collegeReducer,
    students: studentsReducer,
    professor: professorReducer,
    university: universityReducer,
  },
});

export default store;
