import API from './config';

export const createVideo = async(video) => {
  try {
    const res = await API.post('api/videos', video, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateVideo = async(id, video) => {
  try {
    const res = await API.put(`api/videos/${id}`, video, {
      headers: {"Content-Type": 'multipart/form-data'}
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export const getVideoById = async(id) => {
  try {
    const res = await API.get(`api/videos/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

// dashboard

export const getAllVideos = async() => {
  try {
    const res = await API.get(`api/videos`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const toggleVideoActive = async(id) => {
  try {
    const res = await API.put(`api/videos/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const deleteVideo = async(id) => {
  try {
    const res = await API.delete(`api/videos/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}