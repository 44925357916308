import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardCard from "./dashboardCard";
import Sidebar2 from "../Parts/SideBar2";
import { delUniversity, getUniversities, toggleActive } from "../../api/universityApi";
import { selectLoggedInUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { UnsavedChangesContext } from "../../context/UnsavedChangesContext";

const UniversityDashboard = () => {
  const [universities, setUniversities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { hasUnsavedChanges,setHasUnsavedChanges } = useContext(UnsavedChangesContext);
   console.log("hasUnsavedChanges",hasUnsavedChanges)

  const mainUser = useSelector(selectLoggedInUser);

  const nav = useNavigate();

  const fetchUniversities = async () => {
    try {
      const res = await getUniversities();
      setUniversities(res);
    } catch (err) {
      toast.error("Error fetching universities.");
    }
  };

  const edit = async (uniId) => {
    try {
      nav(`/admin/university/${uniId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteUniversity = async (uniId) => {
    try {
      const res = await delUniversity(uniId);
      console.log(res)

      if (res === "Deleted.") {
        toast.success("University deleted successfully.");
        fetchUniversities();
      } else if (res === undefined) {
        toast.success("University deleted successfully.");
        fetchUniversities();
      } else if (res === "University unavailable.") {
        toast.error("University unavailable.");
      } else {
        toast.error("Unexpected error, try again.");
      }
    } catch (err) {
      toast.error("Error, try again.");
      console.error("Error deleting university:", err);
    }
  };


  useEffect(() => {
    fetchUniversities();
  }, []);

  const handleActive = async (id, stat) => {
    try {
      const updated = await toggleActive(id, { isActive: !stat })
      setUniversities(universities.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const filteredUniversities = universities?.filter((university) =>
    university.overview.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className="flex overflow-hidden">
      <Sidebar2 />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {mainUser.user.name || "Name"}</h1>
        </header>
        <div className="flex flex-col items-center">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">
                University Dashboard
              </h2>
              <p className="text-gray-600 mb-4">Keep track of your universities.</p>
            </div>

            <button
              className="h-[39px] px-6 py-2 mb-4 bg-orange-500 text-white rounded"
              onClick={() => {
                nav("/admin/university");
                setHasUnsavedChanges(true);
              }}
            >
              + Add New University
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2  px-2 py-2 -translate-y-[2px] -translate-x-[14.8px]  ml-[30px] ">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-yx-[38px] -translate-y-[3px]">
          {filteredUniversities?.map((university, index) => (
            <DashboardCard
              key={university._id ?? index}
              name={university.overview.name}
              // code={card.code}
              date={university.createdAt.substring(0, 10)}
              isActive={university.isActive}
              toggleActive={() => handleActive(university._id)}
              edit={() => edit(university._id)}
              deletee={() => deleteUniversity(university._id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UniversityDashboard;
