import API from './config';

// last section

export const addProfessor = async (professor) => {
  try {
    const res = await API.post('professor/add', professor);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editProfessor = async (id, professor) => {
  try {
    const res = await API.patch(`professor/edit/${id}`, professor);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// page

export const getProfessorById = async (id) => {
  try {
    const res = await API.get(`professor/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}

// dashboard

export const getProfessors = async () => {
  try {
    const res = await API.get('professor/get');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`professor/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const delProfessor = async (id) => {
  try {
    await API.delete(`professor/delete/${id}`);
  } catch (error) {
    console.error(error);
  }
};
