import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  info: {
    profilePhoto: '',
    name: {
      first: '',
      middle: '',
      last: ''
    },
    desc: '',
    email: '',
    linkedin: '',
    mobile: ['',],
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    district: '',
    state: ''
  },
  education: {
    secondary: {
      name: '',
      board: '',
      stream: '',
      passingYear: ''
    },
    seniorSec: {
      name: '',
      board: '',
      stream: '',
      marks: '',
      passingYear: ''
    },
    diploma: {
      name: '',
      stream: '',
      passingYear: ''
    },
    college: {
      name: '',
      stream: '',
      branch: '',
      startingYear: '',
      passingYear: ''
    }
  },
  skills: {
    intro: '',
    skill: [
      {
        name: '',
        desc: ''
      }
    ]
  },
  experience: [
    {
      designation: '',
      org: '',
      startDate: '',
      endDate: '',
      desc: ''
    }
  ]
};

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    updatePersonalInfo: (state, action) => {
      state.info = { ...state.info, ...action.payload };
    },
    updateEducationBackground: (state, action) => {
      state.education = { ...state.education, ...action.payload };
    },
    updateIntroText: (state, action) => {
      state.skills.intro = action.payload;
    },
    updateSkill: (state, action) => {
      const { index, name, desc } = action.payload;
      state.skills.skill[index] = { name, desc };
    },
    addSkill: (state) => {
      state.skills.skill.push({ name: '', desc: '' });
    },
    updateExperience: (state, action) => {
      const { index, experience, delete: flag } = action.payload;

      if (flag) state.experience = state.experience.filter((_, i) => i !== index);
      else state.experience[index] = { ...state.experience[index], ...experience };
    },
    addExperience: (state) => {
      state.experience.push({
        designation: '',
        org: '',
        startDate: '',
        endDate: '',
        desc: ''
      });
    },
    updateStudentUserId: (state, action) => {
      state.userId = action.payload;
    },
    resetStudents: () => initialState,
    updateSkillsAndIntroText: (state, action) => {
      const { intro, skill } = action.payload;
      state.skills.intro = intro;
      state.skills.skill = skill;
    },
    updateStudentData: (state, action) => { // Add this reducer
      return { ...state, ...action.payload };
    }
  }
});

export const {
  updatePersonalInfo,
  updateEducationBackground,
  updateIntroText,
  updateSkill,
  addSkill,
  updateExperience,
  addExperience,
  updateStudentUserId,
  resetStudents,
  updateSkillsAndIntroText,
  updateStudentData,
} = studentsSlice.actions;

export const selectStudent = (state) => state.students;

export default studentsSlice.reducer;
