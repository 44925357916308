import React from "react";

// import calendar from "../../assets/calendar.svg";

const FieldCol2 = ({
  col1Name,
  col1Value,
  col2Name,
  col2Value,
  addressReq,
  landmarkReq,
  name1,
  name2,
  value1,
  value2,
  handleInput
}) => {
  return (
    <div className=" self-stretch justify-start items-start gap-6 inline-flex">
      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor={name1}
              className="text-zinc-700 text-base font-semibold leading-tight"
            >
              {col1Name}{addressReq && <span className="text-red-500">*</span>}
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  type="text"
                  required
                  id={name1}
                  name={name1}
                  value={value1}
                  placeholder={col1Value}
                  onChange={handleInput}
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grow shrink basis-0 h-[70px] justify-start items-start gap-8 flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
            <label
              htmlFor={name2}
              className="text-zinc-700 text-base font-semibold leading-tight"
            >
              {col2Name}{landmarkReq && <span className="text-red-500">*</span>}
            </label>
            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <input
                  type="text"
                  required
                  id={name2}
                  name={name2}
                  value={value2}
                  placeholder={col2Value}
                  onChange={handleInput}
                  className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldCol2;
