import React, { useEffect, useRef, useState } from "react";
import SideBar from "../Parts/SideBar2";

import cloud from "../../assets/upload-cloud-02.svg";
import FieldCol1 from "../Parts/FieldCol1";
import { createPdf, getPdfById, updatePdf } from "../../api/pdfApi";
import { useNavigate, useParams } from "react-router-dom";
import { getProfessors } from "../../api/professorApi";
import RefDropDown from "../Parts/RefDropDown";
import { toast } from 'react-toastify';
import Imagekit from "../Parts/Imagekit";

const Pdf = () => {
  const { id } = useParams();
  const nav = useNavigate();

  const [pdfData, setPdfData] = useState({
    numberOfPages: "",
    pdfName: "",
    creator: "",
    description: "",
    fileUrl: "",
  });
  const sectionRef = useRef(pdfData);

  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState("");
  const [professors, setProfessors] = useState([]);

  useEffect(() => {
    fetchProfessors();
    if (id) fetchPdfData();
  }, [id]);

  const fetchProfessors = async () => {
    try {
      const res = await getProfessors();
      const data = res.map(p => p = { _id: p._id, creator: `${p.info.name.first} ${p.info.name.last}` });
      setProfessors(data);
      // console.log("professors: ", professors);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchPdfData = async () => {
    try {
      const res = await getPdfById(id);
      setPdfData(res);
      setUploadedFile(res.pdfName);
      // console.log(res);
    } catch (error) {
      console.error("pdf:", error);
    }
  };

  const handleInput = (updatedData) => {
    sectionRef.current = updatedData;
    setPdfData(updatedData);
  };

  // Handle regular input changes and custom events from FieldCol1
  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedData = { ...pdfData, [id]: value };
    sectionRef.current = updatedData;
    setPdfData(updatedData);
    console.log(`Field ${id} updated to:`, value); // For debugging
  };

  const handleFileUpload = (res) => {
    if (validateFileSize(res.size)) {
      setUploadedFile(res.name); // Optionally set the uploaded file name
      const updatedData = { ...sectionRef.current, fileUrl: res.url };
      sectionRef.current = updatedData;
      setPdfData(updatedData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!pdfData.fileUrl) {
      toast.error("Please select a PDF file.");
      return;
    }
    // const formData = new FormData();
    // formData.append("pdfFile", file);

    // Object.keys(pdfData).forEach((key) => {
    //   formData.append(key, pdfData[key]);
    // });

    try {
      const res = id
        ? await updatePdf(id, pdfData)
        : await createPdf(pdfData);

      // reset all
      setPdfData({
        numberOfPages: "",
        pdfName: "",
        creator: "",
        description: "",
        fileUrl: "",
      });
      // setFile(null);
      setUploadedFile("");

      nav('/dashboard/document');
    } catch (error) {
      console.error(error);
    }
  };

  const validateFileSize = (fileSize) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 1 MB
    if (fileSize > MAX_FILE_SIZE) {
      toast.error("File size should not exceed 100 KB");
      return false;
    }
    return true;
  };

  return (
    <div className="bg-purple-50">
      <div className="justify-start items-start inline-flex h-auto">
        <SideBar />
        <form
          onSubmit={handleSubmit}
          className="ml-[15.5vw] grow shrink basis-0 self-stretch bg-purple-50 justify-start items-start flex"
        >
          <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
            <div className="grow shrink basis-0 px-8 justify-center items-start flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch justify-start items-start gap-8 inline-flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                        <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                          <div className="text-zinc-700 text-4xl font-bold font-['Inter'] leading-tight">
                            Upload PDF
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor="fileUrl"
                      className="w-[512px] flex-col justify-start items-start gap-4 inline-flex cursor-pointer"
                    >
                      <div className="self-stretch h-[126px] bg-gray-50 rounded-xl border border-gray-900 flex-col justify-start items-center gap-1 flex">
                        {/* <input
                          type="file"
                          id="fileUrl"
                          accept=".pdf"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            setUploadedFile(e.target.files[0].name);
                          }}
                          className="hidden"
                        /> */}
                        <Imagekit
                          id="fileUrl"
                          accept=".pdf"
                          onSuccess={(res) => {
                            console.log("res: ", res);
                            handleFileUpload(res);
                          }}
                        />
                        <div className="self-stretch h-full justify-center items-center gap-10 flex">
                          <div className=" flex-col justify-start items-start gap-1 flex">
                            <div className="self-stretch justify-center items-start gap-1 inline-flex">
                              <div className="justify-center items-center gap-2 flex">
                                <p className="text-gray-900 text-sm font-semibold leading-tight">
                                  {uploadedFile || "Upload PDF"}
                                </p>
                              </div>
                            </div>
                            <p className="self-stretch text-center text-zinc-700 text-xs font-normal leading-[18px]">
                              .pdf accepted (maximum 7MB)
                            </p>
                          </div>
                          {!uploadedFile && (
                            <div className="w-15 h-15 p-2.5 bg-gray-200 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                              <img
                                src={cloud}
                                alt="cloud"
                                className="w-[22px] h-[22px] relative flex-col justify-start items-start flex"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </label>

                    <div className=" self-stretch justify-start items-start gap-6 inline-flex">
                      <div className="self-stretch justify-start items-start gap-8 flex">
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="numberOfPages"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              Number of Pages
                            </label>
                            <div className=" flex-col justify-start items-start flex">
                              <div className="h-11 px-4 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                                <input
                                  id="numberOfPages"
                                  type="number"
                                  value={pdfData.numberOfPages}
                                  onChange={handleChange}
                                  placeholder="7"
                                  required
                                  min={1}
                                  className="text-slate-700 py-2.5 text-sm font-medium font-['Inter'] leading-tight focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-[70px] justify-start items-start gap-8 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor="pdfName"
                              className="text-zinc-700 text-base font-semibold font-['Inter'] leading-tight"
                            >
                              PDF Name
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id="pdfName"
                                  type="text"
                                  value={pdfData.pdfName}
                                  onChange={handleChange}
                                  required
                                  placeholder="this (.pdf)"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal font-['Inter'] leading-normal focus:outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="justify-start items-start gap-8 flex">
                        <div className="flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">

                            <RefDropDown fieldName={"Creator"} array={professors} currentValue={pdfData.creator} display={"creator"} theValue={"_id"} handleInput={handleChange} />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
                      <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
                        <FieldCol1
                          fieldName={"PDF Description"}
                          fieldPlace={"This PDF contains XXX..."}
                          name={"description"}
                          value={pdfData.description}
                          handleInput={handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-full max-w-[77vw] h-12 flex-col justify-start items-start gap-4 flex">
                      <button
                        type="submit"
                        className="self-stretch px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 inline-flex"
                      >
                        <p className="text-white text-base font-semibold font-['Inter'] leading-normal">
                          Submit
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pdf;
